import { Skeleton, Space } from 'antd';
import AppColors from 'config/AppColors';
import { createUseStyles } from 'react-jss';

export type SkeletonMessagesProps = {
  numberOfTupleMessages?: number;
};

const useStyles = createUseStyles({
  container: {
    flex: 1,
    backgroundColor: AppColors.backgroundGrey,
  },
  subContainer: {
    display: 'grid',
    padding: 20,
  },
  loaderAvatar: {
    alignItems: 'center',
    display: 'flex',
    '& .ant-skeleton-header': {
      padding: 0,
    },
  },
  loaderRight: {
    justifyContent: 'end',
  },
});

export default function SkeletonMessages(props: SkeletonMessagesProps = { numberOfTupleMessages: 1 }) {
  const { numberOfTupleMessages } = props;

  // Hooks
  const styles = useStyles();

  const renderMessageLeft = () => (
    <Space>
      <Skeleton avatar active paragraph={{ rows: 0 }} className={styles.loaderAvatar} />
      <Skeleton.Input size="large" />
    </Space>
  );
  const renderMessageRight = () => (
    <Space className={styles.loaderRight}>
      <Skeleton.Input size="large" />
      <Skeleton avatar active paragraph={{ rows: 0 }} className={styles.loaderAvatar} />
    </Space>
  );

  const renderTupleMessages = (key: string) => (
    <div key={key}>
      {renderMessageLeft()}
      {renderMessageRight()}
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {[...Array(numberOfTupleMessages)].map((value, index) => renderTupleMessages(`skeleton-message-${index}`))}
      </div>
    </div>
  );
}
