/* eslint-disable no-param-reassign */
import * as amplitude from '@amplitude/analytics-browser';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserDTO } from 'services/api/models/UserDTO';
import resetAll from 'store/shared/resetAll';
import createSlice from 'utils/@reduxjs/toolkit';
import { UserState } from './types';

export const initialState: UserState = {
  currentUser: undefined,
};

const slice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder: any) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserDTO>) => {
      state.currentUser = action.payload;
      const identifyEvent = new amplitude.Identify();
      identifyEvent.setOnce('gynger_is_caregiver', action.payload.is_caregiver);
      amplitude.identify(identifyEvent);
      amplitude.setUserId(action.payload.id);
    },
    disableNPSSurvey: state => {
      if (state.currentUser) state.currentUser = { ...state.currentUser, should_ask_for_nps: false };
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: userActions, reducer: userReducer } = slice;
