module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Je n’ose pas parler de mes dyspareunies avec mon partenaire, comment faire ?
          </h1>

          <div class="date-modified color-secondary">Publié le 6 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Aborder les dyspareunies avec son partenaire est crucial pour maintenir une relation saine et compréhensive. Voici
              quelques conseils pour entamer cette conversation délicate dans le respect et la bienveillance :
            </p>
            <p class="paragraph">
              <b>Choisissez le bon moment et le bon endroit</b> : Trouvez un moment calme et confortable où vous pourrez discuter
              sans être interrompus. Le respect mutuel est essentiel. Vous pouvez également opter pour un lieu neutre pour plus de
              confort.
            </p>
            <p class="paragraph">
              <b>Exprimez vos sentiments en premier</b> : Commencez la conversation en partageant vos sentiments de manière douce
              et authentique. Par exemple, dites à votre partenaire que vous aimeriez discuter d'un sujet important pour vous et
              pour votre relation.
            </p>
            <p class="paragraph">
              <b>Expliquez les dyspareunies sans termes médicaux compliqués</b> : Utilisez des termes simples pour décrire ce que
              vous ressentez, en évitant les jargons médicaux. Expliquez simplement que vous ressentez de la douleur pendant les
              rapports et que cela affecte votre bien-être émotionnel.
            </p>
            <p class="paragraph">
              <b>Encouragez l'empathie</b> : Invitez votre partenaire à partager ses propres sentiments et assurez-vous de créer un
              espace où il se sentira libre de s'exprimer. L'empathie est essentielle pour une compréhension mutuelle.
            </p>
            <p class="paragraph">
              <b>Évitez le blâme</b> : Évitez de blâmer votre partenaire. Les dyspareunies sont souvent causées par des facteurs
              médicaux ou émotionnels et non par une faute personnelle. En évitant le blâme, vous favorisez une communication
              ouverte et honnête.
            </p>
            <p class="paragraph">
              <b>Proposez une visite chez le professionnel de santé</b> : Suggérez d'explorer la situation ensemble en consultant
              un professionnel de santé. Cela montre que vous êtes prêt à résoudre le problème en équipe et permettra d'identifier
              les causes sous-jacentes de la dyspareunie.
            </p>
            <p class="paragraph">
              <b>Maintenez la communication</b> : Encouragez une communication continue sur le sujet et assurez à votre partenaire
              que vous appréciez son soutien. Montrez que vous êtes prêt(e) à travailler ensemble pour trouver des solutions.
            </p>
            <p class="paragraph">
              <b>Considérez des alternatives aux rapports sexuels</b> : En attendant une consultation médicale, explorez des
              alternatives pour maintenir l'intimité. Cela peut inclure des câlins, des activités physiques non-sexuelles, et
              d'autres moyens d'exprimer l'amour et la connexion.
            </p>
            <p class="paragraph">
              En suivant ces conseils, vous pouvez aborder les dyspareunies avec votre partenaire de manière constructive et
              empathique, renforçant ainsi votre relation dans le respect et la compréhension mutuelle.
            </p>
          </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
