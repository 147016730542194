import { CheckOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Col, Row, Space } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import AppColors from 'config/AppColors';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectCurrentConversationId, selectCurrentConversationSummary } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';

const useStyles = createUseStyles({
  container: {
    boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1)',
  },
  row: {
    padding: 12,
  },
  caregiverList: {},
  caregiverItem: {
    backgroundColor: AppColors.backgroundGrey,
    borderRadius: 5,
    marginTop: 5,
    cursor: 'pointer',
  },
});

export default function ConversationButtons() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  // Selectors
  const currentConversationId = useSelector(selectCurrentConversationId);
  const currentConversation = useSelector(selectCurrentConversationSummary);

  const { loading: loadingSelfAssign, run: selfAssignConversation } = useRequest(
    conversationId => ApiDoctorConversationsManager.selfAssignConversation({ conversationId }),
    {
      manual: true,
      onSuccess: () => {
        // Once the conversation has been transferred to the current caregiver, we can:
        // Update the local store to mark the conversation as part of 'My chats' ...
        if (currentUser) {
          dispatch(conversationActions.setOwnerForCurrentConversation(currentUser));
        }

        api.success({
          message: t('caregivers.actions.selfAssign.success'),
        });
      },
      onError: () => {
        api.error({
          message: t('caregivers.actions.selfAssign.error'),
        });
      },
    },
  );

  function selfAssign() {
    selfAssignConversation(currentConversationId);
  }

  const isCurrentUserIsOwner =
    currentConversation &&
    currentConversation?.conversation &&
    currentConversation?.conversation.current_owner &&
    currentConversation.conversation.current_owner.id === currentUser?.id;

  // Show the self assign button if:
  // - there is no owner yet
  // - or there is an owner, but this is not the current user/caregiver
  const displaySelfAssign =
    !currentConversation?.conversation.current_owner ||
    !isCurrentUserIsOwner ||
    currentConversation?.type === 'unassigned';

  if (!displaySelfAssign) {
    return null;
  }

  return (
    <Row justify="center" className={styles.container}>
      {context}
      <Col className={styles.row}>
        <Space>
          <GGButton
            type="default"
            size="large"
            icon={<CheckOutlined />}
            onClick={() => selfAssign()}
            disabled={loadingSelfAssign}
            loading={loadingSelfAssign}
          >
            {t('chats.actions.selfAssign')}
          </GGButton>
        </Space>
      </Col>
    </Row>
  );
}
