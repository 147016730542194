/* eslint-disable global-require */
import { KeyOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input } from 'antd';
import GGButton from 'components/buttons/GGButton';
import useAuthGuard from 'hooks/useAuthGuard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import AuthContainer, { AuthContainerProps } from '../containers/AuthContainer';

export type LoginRegisterFormProps = AuthContainerProps & {
  buttonTitle: string;
  buttonLoading: boolean;
  onButtonClick: (params: {
    email: string;
    password: string;
    first_name?: string;
    last_name?: string;
    re_password?: string;
    license_code?: string;
  }) => void;
};

const useStyles = createUseStyles({
  textInput: {
    fontSize: 16,
    textTransform: 'lowercase',
  },
});

export default function LoginRegisterForm(props: LoginRegisterFormProps) {
  const { title, description, buttonTitle, mode, onButtonClick, buttonLoading, bottomLinkTo } = props;

  // Hooks
  const { canCallApi, isLoading } = useAuthGuard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();

  useEffect(() => {
    if (canCallApi) {
      navigate(routes.home.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCallApi]);

  const onFinish = (values: any) => {
    onButtonClick({
      email: values.email,
      password: values.password,
      ...(mode === 'register' && {
        first_name: values.first_name,
        last_name: values.last_name,
        license_code: values.license_code,
        re_password: values.re_password,
        tc_and_privacy_policy_consent: values.tc_and_privacy_policy_consent,
        personal_information_access_consent: values.personal_information_access_consent,
        email_notifications_consent: values.email_notifications_consent ?? false,
      }),
    });
  };

  const inlineStyles = {
    passwordResetAction: {
      marginBottom: 24,
      textAlign: 'right' as const,
    },
    passwordResetActionLink: {
      textDecoration: 'underline',
    },
  };

  const validateEmail = (rule: any, value: any, callback: any) => {
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    if (!emailRegex.test(value)) {
      callback(t('loginRegister.fields.email.errors.notEmail'));
    } else {
      callback();
    }
  };

  return (
    <AuthContainer title={title} description={description} mode={mode} bottomLinkTo={bottomLinkTo}>
      <Form
        name="normal_login"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        initialValues={{ email_notifications_consent: true }}
      >
        <Form.Item name="email" rules={[{ required: true }, { validator: validateEmail }]}>
          <Input
            prefix={<UserOutlined />}
            autoCapitalize="none"
            placeholder={t('loginRegister.fields.email.placeholder')}
            allowClear
            className={styles.textInput}
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true }, { min: 8, message: t('passwordReset.fields.password.errors.minLength') }]}
          style={{ marginBottom: 5 }}
        >
          <Input.Password
            prefix={<LockOutlined />}
            autoCapitalize="none"
            placeholder={t('loginRegister.fields.password.placeholder')}
            allowClear
            className={styles.textInput}
            autoComplete="current-password"
          />
        </Form.Item>
        {mode === 'login' && (
          <div style={inlineStyles.passwordResetAction}>
            <Link to={routes.passwordResetSendEmail.route} style={inlineStyles.passwordResetActionLink}>
              {t('loginRegister.actions.passwordReset')}
            </Link>
          </div>
        )}

        {mode === 'register' && (
          <Form.Item
            name="re_password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('loginRegister.fields.passwordConfirmation.errors.required'),
              },
              { min: 8, message: t('passwordReset.fields.password.errors.minLength') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('loginRegister.fields.passwordConfirmation.errors.passwordsNotMatch')),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              autoCapitalize="none"
              prefix={<LockOutlined />}
              placeholder={t('loginRegister.fields.passwordConfirmation.placeholder')}
              allowClear
              className={styles.textInput}
              autoComplete="current-password"
            />
          </Form.Item>
        )}

        {mode === 'register' && (
          <Form.Item
            name="first_name"
            rules={[{ required: true, message: t('loginRegister.fields.firstName.errors.required') }]}
            style={{ marginBottom: 5 }}
          >
            <Input placeholder={t('loginRegister.fields.firstName.placeholder')} allowClear />
          </Form.Item>
        )}
        {mode === 'register' && (
          <Form.Item
            name="last_name"
            rules={[{ required: true, message: t('loginRegister.fields.lastName.errors.required') }]}
          >
            <Input placeholder={t('loginRegister.fields.lastName.placeholder')} allowClear />
          </Form.Item>
        )}
        {mode === 'register' && (
          <Form.Item
            name="license_code"
            rules={[{ required: true, message: t('loginRegister.fields.licenseCode.errors.required') }]}
          >
            <Input
              prefix={<KeyOutlined />}
              placeholder={t('loginRegister.fields.licenseCode.placeholder')}
              allowClear
              className={styles.textInput}
            />
          </Form.Item>
        )}
        {mode === 'register' && (
          <Form.Item
            name="tc_and_privacy_policy_consent"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t('loginRegister.fields.tc_and_privacy_policy_consent.errors.required')),
                      ),
              },
            ]}
            style={{ marginBottom: 10 }}
            valuePropName="checked"
          >
            <Checkbox>
              <Link to={t('legal.termsAndPrivacy')} target="_blank" style={{ textDecoration: 'underline' }}>
                {t('loginRegister.fields.tc_and_privacy_policy_consent.title')}
              </Link>{' '}
              *
            </Checkbox>
          </Form.Item>
        )}
        {mode === 'register' && (
          <Form.Item
            name="personal_information_access_consent"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t('loginRegister.fields.personal_information_access_consent.errors.required')),
                      ),
              },
            ]}
            style={{ marginBottom: 10 }}
            valuePropName="checked"
          >
            <Checkbox>{t('loginRegister.fields.personal_information_access_consent.title')} *</Checkbox>
          </Form.Item>
        )}
        {mode === 'register' && (
          <Form.Item name="email_notifications_consent" valuePropName="checked">
            <Checkbox>{t('loginRegister.fields.email_notifications_consent.title')}</Checkbox>
          </Form.Item>
        )}

        <Form.Item style={{ marginBottom: '0px' }}>
          <GGButton
            block
            type="primary"
            htmlType="submit"
            disabled={isLoading || buttonLoading}
            loading={isLoading || buttonLoading}
          >
            {buttonTitle}
          </GGButton>
        </Form.Item>
      </Form>
    </AuthContainer>
  );
}
