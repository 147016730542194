import { Button, ButtonProps } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    borderRadius: 5,
  },
});

export default function GGButton(props: ButtonProps) {
  const styles = useStyles();

  return <Button {...props} className={styles.button} />;
}
