/* eslint-disable global-require */
import { Segmented } from 'antd';
import PageTopBar from 'components/navigation/PageTopBar';
import SkeletonChats from 'components/skeleton/SkeletonChats';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import {
  selectClosedConvesrations,
  selectConversationLoadingStatusIsLoading,
  selectConversations,
  selectMyRequestsConversations,
  selectOnHoldConversations,
  selectUnassignedConversations,
  selectWaitingForReassignmentConversations,
} from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import { RootState } from 'types';
import ChatsList from './ChatsList';
import NewChatPanel from './NewChatPanel';

export type UnassignedConversationSegmentTypes = 'unassigned' | 'myRequest' | 'waitingForReassignment';
export type AssignedConversationSegmentTypes = 'assigned' | 'onHold' | 'closed';

export default function ChatsPanel() {
  // Selectors
  const currentUser = useSelector(selectCurrentUser);
  const conversations = useSelector(
    (state: RootState) => selectConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const unassignedConversations = useSelector(
    (state: RootState) => selectUnassignedConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const myRequestsConversations = useSelector(
    (state: RootState) => selectMyRequestsConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const waitingForReassignmentConversations = useSelector(
    (state: RootState) => selectWaitingForReassignmentConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const onHoldConversations = useSelector(
    (state: RootState) => selectOnHoldConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const closedConversations = useSelector(
    (state: RootState) => selectClosedConvesrations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const conversationLoadingStatusIsLoading = useSelector(selectConversationLoadingStatusIsLoading);

  // Hooks
  const { t } = useTranslation();

  // States
  const [unassignedSegmentValue, setUnassignedSegmentValue] =
    useState<UnassignedConversationSegmentTypes>('unassigned');
  const [assignedSegmentValue, setAssignedSegmentValue] = useState<AssignedConversationSegmentTypes>('assigned');

  const renderUnassignedChats = () => {
    if (!currentUser?.is_caregiver) {
      return null;
    }

    const getConversationsListBySegmentValue = () => {
      switch (unassignedSegmentValue) {
        case 'unassigned':
          return {
            title: t('chats.subtitle.unassigned'),
            conversations: unassignedConversations || [],
          };
        case 'myRequest':
          return {
            title: t('chats.subtitle.myRequest'),
            conversations: myRequestsConversations || [],
          };
        case 'waitingForReassignment':
          return {
            title: t('chats.subtitle.waitingForReassignment'),
            conversations: waitingForReassignmentConversations || [],
          };
        default:
          return {
            title: t('chats.subtitle.unassigned'),
            conversations: [],
          };
      }
    };

    const data = getConversationsListBySegmentValue();

    // Map conversations

    return (
      <>
        <Segmented
          options={[
            { label: `${t('chats.subtitle.unassigned')} (${unassignedConversations.length})`, value: 'unassigned' },
            { label: `${t('chats.subtitle.myRequest')} (${myRequestsConversations.length})`, value: 'myRequest' },
            {
              label: `${t('chats.subtitle.waitingForReassignment')} (${waitingForReassignmentConversations.length})`,
              value: 'waitingForReassignment',
            },
          ]}
          block
          onChange={value => setUnassignedSegmentValue(value as UnassignedConversationSegmentTypes)}
          style={{ marginTop: 13, marginLeft: 13, marginRight: 13 }}
        />
        {conversationLoadingStatusIsLoading ? (
          <SkeletonChats numberOfItems={3} />
        ) : (
          <ChatsList title={data.title} conversations={data.conversations} readOnlyConvs={false} />
        )}
      </>
    );
  };

  const renderAssignedChats = () => {
    if (!currentUser?.is_caregiver) {
      return (
        <>
          <NewChatPanel />
          {conversationLoadingStatusIsLoading ? (
            <SkeletonChats numberOfItems={3} />
          ) : (
            <ChatsList
              conversations={conversations}
              title={currentUser?.is_caregiver ? t('chats.subtitle.assigned') : t('chats.title')}
              readOnlyConvs={false}
            />
          )}
        </>
      );
    }

    const getConversationsListBySegmentValue = () => {
      switch (assignedSegmentValue) {
        case 'assigned':
          return {
            title: t('chats.subtitle.assigned'),
            conversations: conversations || [],
          };
        case 'onHold':
          return {
            title: t('chats.subtitle.onHold'),
            conversations: onHoldConversations || [],
          };
        case 'closed':
          return {
            title: t('chats.subtitle.closed'),
            conversations: closedConversations || [],
          };
        default:
          return {
            title: t('chats.subtitle.assigned'),
            conversations: [],
          };
      }
    };

    const data = getConversationsListBySegmentValue();

    return (
      <>
        <Segmented
          options={[
            { label: `${t('chats.subtitle.assigned')}`, value: 'assigned' },
            { label: `${t('chats.subtitle.onHold')} (${onHoldConversations.length})`, value: 'onHold' },
            {
              label: `${t('chats.subtitle.closed')}`,
              value: 'closed',
            },
          ]}
          block
          onChange={value => setAssignedSegmentValue(value as AssignedConversationSegmentTypes)}
          style={{ marginTop: 13, marginLeft: 13, marginRight: 13 }}
        />
        {conversationLoadingStatusIsLoading ? (
          <SkeletonChats numberOfItems={3} />
        ) : (
          <ChatsList title={data.title} conversations={data.conversations} readOnlyConvs={false} />
        )}
      </>
    );
  };

  return (
    <>
      <PageTopBar title={t('appName')} />
      {renderUnassignedChats()}
      {renderAssignedChats()}
    </>
  );
}
