module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Le SOPKoi ?
          </h1>

          <div class="date-modified color-secondary">Publié le 20 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Le syndrome des ovaires polykystiques (SOPK), ou syndrome de Stein-Leventhal. Tout a commencé en 1935, lorsque les
              docteurs Irvin Stein et Michael Leventhal l'ont identifié pour la première fois.
            </p>
            <p class="paragraph">
              Ce syndrome touche entre 5 et 20 % des femmes et est la principale cause d'infertilité chez les femmes en raison de
              problèmes d'ovulation.
            </p>
            <p class="paragraph">
              À l'époque, les médecins ont pensé qu'il s'agissait de kystes sur les ovaires en raison de leur apparence "bosselée"
              lors des explorations chirurgicales. Mais plus tard, on a découvert qu'il s'agissait en réalité de multiples
              follicules, là où les ovules se développent, qui ne parvenaient pas à mûrir correctement.
            </p>
            <p class="paragraph">
              Le diagnostic a évolué au fil du temps. En 1990, il reposait principalement sur deux critères : l'excès d'hormones
              mâles et des problèmes de règles. Puis, en 2003, de nouveaux critères ont été ajoutés, comme des signes observables
              à l'échographie.
            </p>
            <p class="paragraph">
              Le SOPK est lié à un déséquilibre hormonal qui entraîne une surproduction d'hormones mâles par les ovaires.
            </p>
            <p class="paragraph">
              Mais la cause exacte du SOPK reste un mystère. On pense que des facteurs génétiques ou des produits chimiques
              présents dans l'environnement pourraient jouer un rôle. Les chercheurs travaillent encore dessus pour mieux
              comprendre et traiter cette maladie.
            </p>
            <p class="paragraph">
              Malheureusement, il n'existe pas de traitement curatif pour le SOPK. De plus, toutes les femmes touchées ne
              présentent pas les mêmes symptômes, car il existe différents types de SOPK.
            </p>
          </div>
        </div>
      </section>
    </div>
  </body>
</html>
`;
