import { Avatar, Col, Typography } from 'antd';
import defaultCaregiverPhoto from '../assets/experts-man-dark.png';

const { Text } = Typography;

export type CaregiverAvatarProps = {
  avatar: string;
  name?: string;
  caption?: string;
};

const CaregiverAvatar: React.FC<CaregiverAvatarProps> = ({ avatar, name, caption }) => {
  return (
    <div>
      <div>
        <Col>
          <Avatar
            src={avatar || defaultCaregiverPhoto}
            size={{ xs: 50, sm: 50, md: 50, lg: 100, xl: 100, xxl: 100 }}
            alt="profile"
          />
        </Col>
      </div>
      <div>
        <Text>{name}</Text>
      </div>
      <div>
        <Text type="secondary">{caption}</Text>
      </div>
    </div>
  );
};
export default CaregiverAvatar;
