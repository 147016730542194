/* eslint-disable global-require */
import { LockOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Form, Input, Typography } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import AuthContainer from 'components/containers/AuthContainer';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiUsersManager from 'services/api/ApiUsersManager';

const { Text } = Typography;

const useStyles = createUseStyles({
  textInput: {
    fontSize: 16,
    textTransform: 'lowercase',
  },
});

export default function PasswordResetPage() {
  // Hooks
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const urlParams = useParams();
  const navigate = useNavigate();
  const styles = useStyles();

  const { loading, run, error } = useRequest(params => ApiUsersManager.resetPassword(params), {
    manual: true,
    onSuccess: () => {
      api.success({
        message: t('passwordReset.success.reset'),
      });

      setTimeout(() => navigate(routes.login.route), 1000);
    },
    onError: () => {
      api.error({
        message: t('passwordReset.errors.reset'),
      });
    },
  });

  const onFinish = (values: { new_password: string; re_new_password: string }) => {
    run({
      uid: urlParams.uid,
      token: urlParams.token,
      new_password: values.new_password,
      re_new_password: values.re_new_password,
    });
  };

  return (
    <AuthContainer
      title={t('passwordReset.title')}
      description={t('passwordReset.description.reset')}
      mode="passwordReset"
      bottomLinkTo="login"
    >
      {context}
      <Form name="normal_login" onFinish={onFinish} layout="vertical" requiredMark="optional">
        <Form.Item
          name="new_password"
          rules={[
            { required: true, message: t('passwordReset.fields.password.errors.required') },
            { min: 8, message: t('passwordReset.fields.password.errors.minLength') },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={t('passwordReset.fields.password.placeholder')}
            allowClear
            className={styles.textInput}
            autoCapitalize="none"
          />
        </Form.Item>
        <Form.Item
          name="re_new_password"
          dependencies={['new_password']}
          rules={[
            {
              required: true,
              message: t('passwordReset.fields.passwordConfirmation.errors.required'),
            },
            { min: 8, message: t('passwordReset.fields.passwordConfirmation.errors.minLength') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('passwordReset.fields.passwordConfirmation.errors.passwordsNotMatch')),
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={t('passwordReset.fields.passwordConfirmation.placeholder')}
            allowClear
            className={styles.textInput}
            autoCapitalize="none"
          />
        </Form.Item>

        {error &&
          (error as any).response &&
          (error as any).response.data &&
          (error as any).response.data.new_password &&
          (error as any).response.data.new_password.length > 0 && (
            <div style={{ marginBottom: 24 }}>
              {(error as any).response.data.new_password.map((errorMessage: string, index: number) => (
                <Text key={index} type="danger">
                  {errorMessage}
                </Text>
              ))}
            </div>
          )}

        <Form.Item style={{ marginBottom: '0px' }}>
          <GGButton block type="primary" htmlType="submit" disabled={loading} loading={loading}>
            {t('passwordReset.actions.reset')}
          </GGButton>
        </Form.Item>
      </Form>
    </AuthContainer>
  );
}
