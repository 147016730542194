module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Est-ce que la Ménopause mettra fin à mon Endométriose ?
          </h1>

          <div class="date-modified color-secondary">Publié le 15 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              L'arrivée de la ménopause est souvent vue comme une perspective de soulagement pour les femmes atteintes
              d'endométriose, marquant la fin des règles et des symptômes liés à la maladie. Cependant, pour certaines, cette
              étape n'est pas nécessairement synonyme de fin des douleurs et des inconforts. Bien que les données scientifiques
              sur ce sujet soient limitées, nous pouvons dresser un état des lieux des recherches actuelles.
            </p>
            <p class="paragraph">
              La ménopause, c'est quoi exactement ? Elle se produit généralement autour de la cinquantaine et se caractérise par
              l'arrêt de l'ovulation et des règles. Cela signifie la fin de la production hormonale, notamment des œstrogènes et
              des progestérones, ainsi que la fin de la formation des ovocytes chaque mois.
            </p>
            <p class="paragraph">
              Un élément clé à comprendre est que l'endométriose est fortement influencée par les œstrogènes. Ainsi, la diminution
              de ces hormones soulage généralement les symptômes de la maladie. Cependant, cette diminution hormonale peut
              également entraîner des symptômes désagréables tels que des bouffées de chaleur, de la fatigue et de l'irritabilité,
              qui peuvent être pris en charge par des traitements hormonaux prescrits par un gynécologue.
            </p>
            <p class="paragraph">
              Mais est-ce que la ménopause signifie la fin de l'endométriose et de ses symptômes pour toutes les femmes ? Pendant
              longtemps, l'idée que l'endométriose ne touchait que les femmes en âge de procréer a persisté. Cependant, des cas
              chez des femmes ménopausées ont été identifiés depuis les années 1940. On estime aujourd'hui que 2 à 5% des femmes
              ménopausées sont affectées par l'endométriose, avec des symptômes similaires à ceux avant la ménopause, tels que des
              douleurs pelviennes et des troubles gastro-intestinaux.
            </p>
            <p class="paragraph">
              Mais alors, comment expliquer la persistance de l'endométriose après la ménopause ? Les causes exactes restent
              floues, mais on suppose que les œstrogènes jouent toujours un rôle prépondérant. Ils peuvent être produits
              localement dans les tissus adipeux ou provenir de traitements hormonaux substitutifs prescrits pour soulager les
              symptômes de la ménopause.
            </p>
            <p class="paragraph">
              Il est important de souligner que la persistance de l'endométriose après la ménopause est rare, mais possible. Il
              est donc essentiel de rester attentif aux symptômes et de consulter un professionnel de santé en cas de doute ou de
              préoccupations. Bien que les options de traitement pour les femmes ménopausées atteintes d'endométriose soient
              limitées, des solutions existent, notamment des traitements hormonaux et des approches chirurgicales.
            </p>
            <p class="paragraph">
              En résumé, bien que la ménopause puisse souvent apporter un soulagement aux femmes atteintes d'endométriose, il est
              important de rester vigilant et de consulter un professionnel de santé en cas de symptômes persistants ou
              préoccupants.
            </p>
          </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
