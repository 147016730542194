export type GGJwt = {
  exp: number;
  iat: number;
  jti: string;
  token_type: 'access' | 'refresh';
  user_id: string;
};

export default class JwtUtils {
  static parseJwt(token: string): GGJwt | undefined {
    if (token === '') {
      return undefined;
    }

    const base64Url = token.split('.')[1];
    if (!base64Url) {
      return undefined;
    }

    const base64 = base64Url.replace('-', '+').replace('_', '/');
    if (!base64) {
      return undefined;
    }

    return JSON.parse(window.atob(base64));
  }

  static isExpired(decodedToken: GGJwt): boolean {
    return decodedToken.exp * 1000 < new Date().getTime();
  }

  static expiresIn(decodedToken: GGJwt): number {
    return decodedToken.exp * 1000 - new Date().getTime();
  }
}
