module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            C'est quoi l'adénomyose?
          </h1>

          <div class="date-modified color-secondary">Publié le 22 juin 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <h3>L'adénomyose: une forme d'endométriose interne à l'utérus</h3>
            <p class="paragraph">L’adénomyose est généralement décrite comme une <b>endométriose interne de l’utérus</b>.</p>
            <p class="paragraph">
              En réalité, elle résulte d’une anomalie de la zone de jonction entre l’endomètre (la muqueuse tapissant l’utérus) et
              le myomètre (la couche musculaire de la paroi utérine). Cette anomalie permet aux cellules de l’endomètre de
              s’infiltrer dans le myomètre. Cette condition peut être superficielle (avec un épaississement de la zone jusqu’à 12
              mm) ou profonde (et souvent douloureuse).
            </p>
            <p class="paragraph">
              <b
                >Bien qu’elle soit fréquente et bénigne d’un point de vue médical – c’est-à-dire qu’elle ne met pas en danger la
                vie – certaines formes d’adénomyose peuvent provoquer des douleurs intenses et devenir très invalidantes au
                quotidien.</b
              >
            </p>
            <p class="paragraph">L’adénomyose peut se présenter sous différentes formes :</p>
            <ul>
              <li><b>Diffuse</b> : avec de nombreux foyers disséminés dans l’ensemble du myomètre.</li>
              <li><b>Focale</b> : avec un ou quelques foyers localisés sur le myomètre.</li>
              <li><b>Externe</b> : lorsque l’endométriose pelvienne profonde infiltre le myomètre.</li>
            </ul>
            <p class="paragraph">
              Il est fréquent que l’adénomyose soit associée à l’endométriose pelvienne, mais il n’y a pas de règle générale. Une
              femme peut avoir de l’adénomyose sans endométriose et inversement.
            </p>
            <p class="paragraph">
              L’adénomyose touche environ 11 à 13 % de la population féminine. Entre 36 et 40 ans, 25 % des femmes sont atteintes.
              Entre 6 et 20 % des cas présentent une association entre adénomyose et endométriose.
            </p>
            <h3>Les facteurs de risque de l'adénomyose: ses origines</h3>
            <p class="paragraph">
              L’adénomyose est plus courante chez les femmes ayant eu plusieurs enfants ou présentant une hyperplasie endométriale
              (un développement excessif de la muqueuse utérine).
            </p>
            <p class="paragraph">
              Il n’est pas encore établi si les chirurgies ou les césariennes peuvent provoquer de l’adénomyose. Cependant, on
              remarque souvent des anomalies placentaires dans les antécédents des patientes atteintes. En revanche, l’utilisation
              d’un stérilet ne constitue pas un facteur de risque.
            </p>
            <h3>Les symptômes de l'adénomyose</h3>
            <ul>
              <li>
                <b>Ménorragies</b> : Règles très abondantes et prolongées (plus de 7 jours avec une perte de sang importante),
                présentes dans 50 % des cas.
              </li>
              <li><b>Dysménorrhées</b> : Douleurs menstruelles, touchant 30 % des femmes atteintes.</li>
              <li><b>Métrorragies</b> : Saignements en dehors des règles, observés dans 20 % des cas.</li>
            </ul>
            <p class="paragraph"><i>À noter : l’adénomyose est asymptomatique dans deux cas sur trois.</i></p>
            <h3>Diagnostiquer l'adénomyose</h3>
            <ul>
              <li>
                <b>L’échographie</b> : Elle permet d’observer une augmentation de la taille de l’utérus, des parois asymétriques,
                et un myomètre enflammé et épaissi. L’utérus perd sa forme triangulaire initiale. L’échographie doit être réalisée
                en seconde partie de cycle, et l’échographie endovaginale (avec une sonde introduite dans le vagin) est plus
                précise.
              </li>
              <li>
                <b>L’IRM</b> : Utilisée en second recours, surtout en cas de suspicion d’endométriose associée, elle se fait de
                préférence en dehors du cycle.
              </li>
              <li>
                <b>L’hystéroscopie ou l’hystérosalpingographie</b> : Ces examens n’apportent aucune valeur ajoutée sauf pour un
                bilan d’infertilité.
              </li>
              <li><b>L’imagerie</b> : Elle aide aussi à différencier un fibrome utérin de l’adénomyose.</li>
            </ul>
            <h3>Les traitements de l'adénomyose</h3>
            <p class="paragraph">
              <i><b>À noter : seuls les cas symptomatiques (douleurs, saignements importants) sont traités.</b></i>
            </p>
            <ul>
              <li>
                <b>Embolisation des artères utérines</b> : Ce traitement conservateur préserve la fertilité, mais près de 50 % des
                femmes traitées voient les symptômes revenir dans les deux ans. Cette procédure consiste à insérer de petites
                billes de gel dans les artères utérines pour réduire l’intensité des ménorragies. Elle est également utilisée pour
                les hémorragies post-partum.
              </li>
              <li>
                <b>Sans désir de grossesse</b>, le traitement vise à soulager les ménorragies et, comme pour l’endométriose, à
                empêcher la stimulation hormonale des tissus endométriaux :
                <ul>
                  <li>
                    <b>GnRH + add-back therapy</b> : Ce traitement réduit l’épaississement de la zone jonctionnelle, le volume
                    utérin et les symptômes associés (dysménorrhée, ménorragies).
                  </li>
                  <li>
                    <b>DIU hormonal </b>(type Miréna) : Diminue les ménorragies, le volume utérin et corrige l’anémie. Efficace
                    pour 3 ans.
                  </li>
                  <li><b>Progestatif en continu</b> : Provoque une atrophie de l’endomètre.</li>
                  <li>
                    <i
                      >L’add-back therapy consiste à réintroduire une petite quantité d’œstrogènes pour compenser les effets
                      secondaires de l’analogue GnRH (ménopause artificielle) sans stimuler les cellules hormono-dépendantes.</i
                    >
                  </li>
                </ul>
              </li>
              <li>
                <b>Traitement radical : hystérectomie</b> (ablation de l’utérus). Si l’adénomyose est associée à une endométriose,
                une hystérectomie seule ne suffira pas.
              </li>
            </ul>
          </div>


        </div>
      </section>
    </div>
  </body>
</html>
`;
