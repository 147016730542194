import { Result } from 'antd';
import GGButton from 'components/buttons/GGButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../services/RouteService';

export default function UnauthorizedPage() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = () => {
    navigate(routes.home.route);
  };

  return (
    <div className="page-layout">
      <div className="page-layout__content">
        <Result
          status="403"
          title={t(`global.errors.${403}.title`)}
          subTitle={t(`global.errors.${403}.message`)}
          extra={
            <GGButton type="primary" onClick={() => onClick()}>
              {t('menu.home')}
            </GGButton>
          }
        />
      </div>
    </div>
  );
}
