import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useLocalStorageState } from 'ahooks';
import { useDispatch } from 'react-redux';
import WebSocketService from 'services/WebSocketService';
import { ApiManager } from 'services/api/ApiManager';
import resetAll from 'store/shared/resetAll';

export default function useLogout() {
  // Hooks
  const dispatch = useDispatch();
  const [, setLocalStorageAccessToken] = useLocalStorageState<string | undefined>('gynger-access-token');
  const [, setLocalStorageRefreshToken] = useLocalStorageState<string | undefined>('gynger-refresh-token');

  const logout = () => {
    // Clear the access token from memory
    ApiManager.setTokens({ accessToken: undefined, refreshToken: undefined });

    // Clear the access token from local storage
    setLocalStorageAccessToken(undefined);
    setLocalStorageRefreshToken(undefined);

    // Reset all stores to initial states
    dispatch(resetAll());

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      // eslint-disable-next-line no-void
      void PushNotifications.unregister();
    }

    // Clear Web Socket connection
    WebSocketService.close();
  };

  return {
    logout,
  };
}
