import { Avatar, Card, Carousel, Tooltip, Typography } from 'antd';
import GGButton from 'components/buttons/GGButton';
import { useTranslation } from 'react-i18next';
import { FaQuoteLeft } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import { conversationActions } from 'store/conversations';
import { selectCurrentUser } from 'store/users/selectors';

export type NewChatPanelProps = {
  title?: string;
};

const useStyles = createUseStyles({
  noChatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 30,
  },
  noChats: {
    marginBottom: 30,
    textAlign: 'center',
  },
  caregiverAvatars: {
    marginTop: 30,
    marginBottom: 10,
  },
  carouselContainer: {
    width: '100%',
  },
  exampleMessageIcon: {
    marginRight: 10,
    color: '#ccc',
  },
  exampleMessage: {
    backgroundColor: '#f3f3f3',
    marginBottom: 5,
    color: '#555',
  },
});

const NewChatPanel: React.FC<NewChatPanelProps> = ({ title }) => {
  // Hooks
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Selectors
  const currentUser = useSelector(selectCurrentUser);

  function newConversation() {
    // Clear the current selected conversation
    dispatch(conversationActions.resetCurrentConversation());
    navigate(routes.newChat.route, { replace: true });
  }

  // Do not show the component if:
  // - there is no title
  // - and the user is a caregiver = we do not show the New Chat button
  // => Nothing to display
  if (!title && currentUser?.is_caregiver) {
    return null;
  }

  return (
    <div className={styles.noChatsContainer}>
      <div className={styles.carouselContainer}>
        <Carousel autoplay>
          {t<string, { returnObjects: true }, string[]>('chats.new.exampleMessages', { returnObjects: true }).map(
            (message, index) => (
              <Card key={`card-${index}`} size="small" bordered={false} className={styles.exampleMessage}>
                <FaQuoteLeft className={styles.exampleMessageIcon} />
                {message}
              </Card>
            ),
          )}
        </Carousel>
      </div>

      {!currentUser?.is_caregiver && (
        <Avatar.Group size="large" className={styles.caregiverAvatars}>
          <Tooltip title={t('chats.caregivers.first')} placement="bottom">
            <Avatar src="https://storage.googleapis.com/gynger-prod-backend-media/media/caregiver_photos/5866194e-52f3-495b-9d80-7a6d58e5907f-f4148.png" />
          </Tooltip>
          <Tooltip title={t('chats.caregivers.second')} placement="bottom">
            <Avatar src="https://storage.googleapis.com/gynger-prod-backend-media/media/caregiver_photos/32fb4719-6944-464c-8db6-ff8f9ec1de74-b0bc3.jpeg" />
          </Tooltip>
          <Tooltip title={t('chats.caregivers.third')} placement="bottom">
            <Avatar src="https://storage.googleapis.com/gynger-prod-backend-media/media/caregiver_photos/d7391265-adc9-42d5-8055-b69d4ee956ff-fa6e3.jpg" />
          </Tooltip>
        </Avatar.Group>
      )}

      {title && <Typography.Text className={styles.noChats}>{title}</Typography.Text>}
      {!currentUser?.is_caregiver && (
        <GGButton size="large" type="primary" onClick={() => newConversation()}>
          {t('chats.actions.initiateConversation')}
        </GGButton>
      )}
    </div>
  );
};
export default NewChatPanel;
