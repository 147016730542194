module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
          Quels symptômes autour de mes seins doivent m’alerter ?
          </h1>

          <div class="date-modified color-secondary">Publié le 17 octobre 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Certains symptômes et signes sont plus fréquents et plus connus que d’autres. En revanche, leur absence n’exclue pas
              la possibilité de présence d’un cancer du sein. Notons également que les signaux d’alerte ne sont pas nécessairement
              localisés au niveau du sein. En somme, il est important de connaitre les signes les plus courants mais tout
              changement inhabituel sur votre corps ou de votre état de santé doit être communiqué à votre médecin.
            </p>
            <ul>
              <li>Boule dans un sein</li>
              <li>
                Ganglions durs au niveau de l’aisselle (sous le bras) (Oui, des foyers peuvent remonter jusque sous le bras sans
                que vous ne ressentiez rien au niveau du sein)
              </li>
              <li>
                Modifications de la peau du sein et du mamelon :
                <ul>
                  <li>rétraction,&nbsp;rougeur, œdème ou aspect de peau d’orange&nbsp;;</li>
                  <li>
                    une modification du mamelon ou de l’aréole (zone qui entoure le mamelon)&nbsp;: rétraction, changement de
                    coloration, suintement ou écoulement&nbsp;;
                  </li>
                </ul>
              </li>
              <li>Changement de la taille ou de la forme du sein</li>
              <li>
                Autres symptômes :
                <ul>
                  <li>des douleurs osseuses ;</li>
                  <li>des nausées, une perte d'appétit, une perte de poids et une jaunisse ;</li>
                  <li>un essoufflement, une toux et une accumulation de liquide autour des poumons (épanchement pleural) ;</li>
                  <li>des maux de tête, une vision double et une faiblesse musculaire.</li>
                </ul>
              </li>
            </ul>
            <p class="paragraph">
              Voici un visuel pratique que nous avons partagé sur notre page Instagram (<a
                href="https://www.instagram.com/gynger.care/"
                target="_blank"
                >@gynger.care</a
              >) :
            </p>
            <p style="text-align: center">
                <img
                  loading="lazy"
                  class="img"
                  src="https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif"
                  style="max-width: 85%"
                  srcset="
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=290&amp;height=289 290w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=345&amp;height=344 345w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=395&amp;height=394 395w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=738&amp;height=735 738w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=812&amp;height=808 812w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=580&amp;height=578 580w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=690&amp;height=688 690w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=790&amp;height=788 790w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=812&amp;height=808 812w,
                    https://unicorn-images.b-cdn.net/dfe41454-8c12-416e-8126-5e5cd394b4d5?optimizer=gif&amp;width=812&amp;height=808 812w
                  "
                  alt=""
                />
            </p>
            <p class="paragraph">
              Sources :
              <a target="_blank" href="https://www.e-cancer.fr/Patients-et-proches/Les-cancers/Cancer-du-sein/Symptomes"
                >https://www.e-cancer.fr/Patients-et-proches/Les-cancers/Cancer-du-sein/Symptomes</a
              >
            </p>
          </div>

      </section>
    </div>
  </body>
</html>
`;
