/* eslint-disable global-require */
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import useAuthGuard from 'hooks/useAuthGuard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContainer from '../components/containers/AuthContainer';

export default function RegisterNoCode() {
  // Hooks
  const { error } = useAuthGuard();
  const { t } = useTranslation();
  const [api, context] = useNotification();

  useEffect(() => {
    if (error) {
      api.error({
        message: t('loginRegister.errors.login'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <AuthContainer
      title={t('loginRegister.title.register')}
      description={t('loginRegister.description.registerNoCode')}
      mode="login"
      bottomLinkTo="login"
    >
      {context}

      <GGButton
        href={`mailto:${t('global.support.codeRequestEmailWithSubject')}`}
        block
        type="primary"
        htmlType="submit"
        disabled={false}
        loading={false}
      >
        {t('loginRegister.actions.askForCode')}
      </GGButton>
    </AuthContainer>
  );
}
