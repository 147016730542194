module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Quelles sont les étapes du diagnostic du SOPK ?
          </h1>

          <div class="date-modified color-secondary">Publié le 24 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Le syndrome des ovaires polykystiques survient généralement après la puberté, souvent pendant l'adolescence.
            </p>
            <p class="paragraph">Cependant, il n'est souvent pas diagnostiqué à ce stade mais bien plus tard.</p>
            <p class="paragraph">
              Les premiers symptômes du SOPK sont généralement des cycles menstruels irréguliers et de l'acné, qui peuvent
              paraître normaux pendant l'adolescence. Généralement, lorsqu'une consultation médicale est effectuée, une pilule
              contraceptive est prescrite pour atténuer ces symptômes, ce qui peut retarder le diagnostic jusqu'à l'arrêt de la
              pilule, lorsque la femme souhaite concevoir un enfant et que ses règles ne reviennent pas.
            </p>
            <p class="paragraph">
              Le diagnostic du SOPK repose sur la présence d'au moins 2 des 3 critères de Rotterdam suivants : une hyperandrogénie
              clinique ou biologique, une ovulation rare ou absente, et des ovaires présentant un aspect « polykystique » à
              l'échographie.
            </p>
            <p class="paragraph">
              Ainsi, un examen échographique doit être complété par une prise de sang. Il est essentiel que les deux examens
              soient réalisés. L'échographie abdominopelvienne permet de visualiser de nombreux petits follicules, avec un nombre
              minimum de 20 et un diamètre inférieur à 9 mm, ainsi qu'un volume ovarien important, sans présence de kystes
              dominants. Habituellement, chaque ovaire contient entre 5 et 10 petits follicules au début du cycle menstruel.
              Cependant, en cas de SOPK, la maturation folliculaire est entravée par un excès d'androgènes, entraînant une
              accumulation de follicules immatures. Cependant, ce critère seul ne suffit pas pour confirmer le diagnostic, car
              certaines femmes peuvent présenter ces résultats à l'échographie sans autres symptômes du SOPK.
            </p>
            <p class="paragraph">
              La prise de sang doit être effectuée entre J+2 et J+5 du cycle menstruel, sans traitement hormonal. Différents
              paramètres hormonaux peuvent être analysés, notamment la testostérone, la delta 4 androstènedione, le DHA sulfate,
              la 17alpha hydroxy progestérone, la LH et la FSH, en fonction des instructions du professionnel de santé.
            </p>
            <p class="paragraph">
              En combinant les résultats de l'échographie et de la prise de sang, un professionnel de santé tel qu'un
              endocrinologue ou un gynécologue peut poser un diagnostic de SOPK.
            </p>
          </div>


        </div>
      </section>
    </div>
  </body>
</html>
`;
