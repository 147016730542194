import axios, { AxiosInstance } from 'axios';

export enum StatusCode {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export class ApiManager {
  private static instance: ApiManager;

  private axiosInstance: AxiosInstance;

  private accessToken?: string;

  private refreshToken?: string;

  private constructor(accessToken?: string) {
    const APIBaseURL = process.env.REACT_APP_API_SERVER_URL;

    this.accessToken = accessToken;
    this.axiosInstance = axios.create({
      baseURL: `${APIBaseURL}`,
      // Cloud run cold start can sometimes reach 10 seconds
      timeout: 15000,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  static getInstance = (accessToken?: string): AxiosInstance => {
    if (!ApiManager.instance) {
      ApiManager.instance = new ApiManager(accessToken);
    }

    return ApiManager.instance.axiosInstance;
  };

  static setTokens = (params: { accessToken?: string; refreshToken?: string }) => {
    // In any cases, create a new Axios instance with a fresh token
    const existingInstance = new ApiManager(params.accessToken);
    if (params.refreshToken) {
      existingInstance.refreshToken = params.refreshToken;
    }
    ApiManager.instance = existingInstance;
  };

  static getTokens = () => {
    if (!ApiManager.instance) {
      return {
        accessToken: undefined,
        refreshToken: undefined,
      };
    }

    const { instance } = ApiManager;
    return {
      accessToken: instance.accessToken,
      refreshToken: instance.refreshToken,
    };
  };

  static getAccessToken = () => {
    if (!ApiManager.instance) {
      return undefined;
    }

    return ApiManager.instance.accessToken;
  };
}
