module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            L'opération - quand faut-il l'envisager?
          </h1>

          <div class="date-modified color-secondary">Publié le 19 juin 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              "Le meilleur moment pour opérer une patiente souffrant d'endométriose est celui où elle souhaite être enceinte, et
              ce d'autant plus qu'à son désir de grossesse sont associées des douleurs intenses."
            </p>
            <p class="paragraph">
              <b>Charles Chapron - dans Idées reçues sur l'endométriose - Editions le Cavalier Bleu - Février 2020</b>
            </p>
            <p class="paragraph">
              ”Etude trés intéressante qui confirme le rôle de l’IRM pelvienne pour sélectionner les patientes qui auraient un
              bénéfice au traitement chirurgical de l’endométriose
            </p>
            <p class="paragraph">
              Les patientes avec atteinte prouvée à la chirurgie, mais sans atteinte visible à l’IRM n’avaient pas de bénéfice en
              terme de douleurs à un traitement chirurgical.”
            </p>
            <p class="paragraph">
              <b>Isabelle Thomassin-Nagara - Présidente de la SIFEM, PUPH Sorbonne Université - Mai 2024</b>
            </p>
            <h2>Le traitement chirurgical de l'endométriose</h2>
            <p class="paragraph">
              Une intervention chirurgicale&nbsp;peut être&nbsp;nécessaire. Elle est alors programmée en concertation avec
              plusieurs spécialistes&nbsp;: gynéco-obstétricien, urologue (spécialiste de l’appareil urinaire), chirurgien
              digestif, etc.
            </p>
            <p class="paragraph">
              Cette chirurgie est complexe, surtout si des lésions sont implantées sur des organes fonctionnels (vessie, rectum,
              colon, etc.). Elle doit donc être menée par des chirurgiens experts de l’endométriose.
            </p>
            <p class="paragraph">L'intérêt de la chirurgie est étudié&nbsp;:</p>
            <ul>
              <li>en cas <b>d’endométriose</b>&nbsp;douloureuse, non contrôlé par le traitement médicamenteux&nbsp;;</li>
              <li>
                en présence d'une&nbsp;<a
                  target="_blank"
                  href="https://www.ameli.fr/paris/assure/sante/themes/sterilite-pma-infertilite/prise-charge-infertilite"
                  >infertilité</a
                >&nbsp;avec désir de grossesse&nbsp;et selon les organes atteints par l'endométriose, sous réserve que la
                chirurgie apporte plus d’avantages (ex.&nbsp;: diminution des douleurs, amélioration de la fécondité) que
                d’inconvénients (ex.&nbsp;:&nbsp;<a
                  target="_blank"
                  href="https://www.ameli.fr/paris/assure/sante/themes/incontinence-urinaire/mecanismes-frequence-causes"
                  >incontinence urinaire</a
                >).
              </li>
            </ul>
            <p class="paragraph">
              L’intervention permet dans un premier temps de faire le point sur l’étendue et la nature des&nbsp;<b
                >lésions d’endométriose</b
              >. Puis, elle consiste à détruire les lésions (par coagulation ou vaporisation au&nbsp;laser) ou à les retirer
              (exérèse).&nbsp;La difficulté chirurgicale est&nbsp;augmentée par la présence de petites lésions
              disséminées.&nbsp;La chirurgie&nbsp;est réalisée le plus souvent sous&nbsp;<a
                target="_blank"
                href="https://www.ameli.fr/paris/assure/sante/examen/exploration/deroulement-coelioscopie"
                >cœlioscopie</a
              >, technique&nbsp;qui facilite les suites opératoires et limite les adhérences.
            </p>
            <p class="paragraph">
              En cas <b>d’endométriose</b>&nbsp;profonde ou étendue, une laparotomie (ouverture de la paroi abdominale) peut être
              nécessaire et&nbsp;l'intervention est longue. Parfois l’ablation d une lésion digestive basse du rectum nécessite la
              mise en place d'une poche de dérivation des matières fécales pendant 1 à 2&nbsp;mois.
            </p>
            <p class="paragraph">
              Après l’opération, un traitement hormonal est prescrit, en l'absence de désir de grossesse,&nbsp;pour réduire le
              risque de récidive douloureuse. <b>À noter</b> :&nbsp;Avant d'être opéré(e), une consultation est obligatoire auprès
              d'un médecin anesthésiste.
            </p>
            <p class="paragraph"><b>L'avis du directeur médical de Gynger</b></p>
            <p class="paragraph">
              ”L’acte chirurgical est un acte lourd de conséquences. Certaines patientes nous rapportent qu’il a été bénéfique
              pour elles. D’autres nous disent avoir été opérées des dizaines de fois car leurs lésions et adhérences revenaient
              sans cesse. Néanmoins, l’opération peut être une bonne solution pour des cas avérés et bien réfléchis. Les lésions
              et adhérences d’endométriose doivent être certaines et visibles à l’IRM. L’impact sur la qualité de vie doit être
              aussi avéré. Il n’y a pas lieu de créer des cicatrices physiques voire psychologiques à vie sur une femme pour qui
              les symptômes sont faibles ou qui a une endométriose asymptomatique. Prenez toujours le temps de réfléchir, vous
              êtes maitre de votre corps et avait toujours le choix ! Nul n’a le droit de vous obliger à quoi que ce soit. La
              prise en charge de l’endométriose est globale et ne se résume pas seulement à de la chirurgie. Prenez le temps
              d’évaluer toutes les solutions qui s’offrent à vous. Veillez toujours à ce que vous échangiez avec des
              professionnels bienveillants et surtout formés à l’endométriose. Il faut le reconnaitre, la chirurgie est un acte
              lucratif qui pourrait malheureusement pousser certains professionnels à vous le suggérer sans raisons totalement
              avérées mais heureusement, ceci n’est pas une généralité. Attention également, l’hystérectomie, c’est à dire
              l’ablation de l’utérus est un acte irréversible et n’est pas recommandé dans l’endométriose. Il peut être
              éventuellement discuté dans l’adénomyose. Prenez toujours le temps d’en discuter avec plusieurs personnes et
              plusieurs professionnels de santé ! Alors si besoin, parlez-en à votre sage-femme référente sur Gynger !”
              <b>Quentin MARQUET, pharmacien et directeur médical de Gynger</b>
            </p>
            <p class="paragraph">
              Etude mentionnée par Isabelle Thomassin-Nagara :
              <a target="_blank" href="https://www.sciencedirect.com/science/article/pii/S2468718924001673"
                >https://www.sciencedirect.com/science/article/pii/S2468718924001673</a
              >
            </p>
          </div>


        </div>
      </section>
    </div>
  </body>
</html>
`;
