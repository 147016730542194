module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
          Quelques chiffres sur le cancer du sein
          </h1>

          <div class="date-modified color-secondary">Publié le 6 octobre 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Le cancer du sein est le cancer <b>le plus fréquent chez la femme</b>. Il représente plus d'un tiers (33 %) de
              l'ensemble des nouveaux cas de cancer chez les femmes. <b>1 femme sur 8 sera atteinte</b> du cancer du sein au cours
              de sa vie.
            </p>
            <p class="paragraph"><b>Généralités :</b></p>
            <ul>
              <li>Nombre de <b>nouveaux cas</b> estimés de cancer du sein en 2023 :&nbsp;61 214</li>
              <li><b>12 600 décès</b> en 2021</li>
              <li><b>Âge moyen</b> au diagnostic en 2023 :&nbsp;64 ans</li>
              <li>Près de <b>80% des cancers</b> du sein se développent après 50 ans.</li>
              <li><b>Taux de survie</b> nette standardisée&nbsp;: <b>88% à 5 ans.</b></li>
            </ul>
            <p class="paragraph"><b>Cancer du sein et travail :</b></p>
            <p class="paragraph">
              Plus de <b>20 % des femmes</b> qui ont eu un cancer du sein <b>ne travaillent pas deux ans après </b>le diagnostic.
            </p>
            <p class="paragraph"><b>Dépistage :</b></p>
            <p class="paragraph">
              <b>Dès l’âge de 25 ans</b>, il est recommandé de consulter pour une&nbsp;<b>palpation bilatérale des seins</b>, peu
              importe le niveau de risque de cancer du sein. <b>Entre 50 et 74 ans</b>, la Haute Autorité de Santé (HAS)
              recommande de faire une&nbsp;<b>mammographie tous les 2 ans.</b> Cette recommandation est valable en l’absence de
              facteurs de risques particuliers de cancer du sein. Après 74 ans, le dépistage n’est pas abandonné, mais
              individualisé.
            </p>
            <p class="paragraph"><b>Mammographie :</b></p>
            <p class="paragraph">
              À titre indicatif, si une femme suit strictement la recommandation de participation au programme de dépistage
              organisé de 50 à 74 ans, elle réalisera 1<b>3 mammographies au total. </b>
            </p>
            <p class="paragraph">
              La <b>compression</b> pendant une mammographie dure à peine <b>5 secondes</b>. Les mammographies représentent en
              France moins de <b>2 % de l’exposition totale </b>de la population aux <b>rayonnements ionisants</b>.
            </p>
            <p class="paragraph">
              Le risque de <b>décès par cancer radio-induit</b> est de l’ordre de <b>1 à 10 pour 100 000 femmes </b>ayant réalisé
              une mammographie tous les 2 ans pendant 10 ans. Le nombre de décès évités avec le dépistage est largement supérieur
              au risque de décès par cancer radio-induit.
            </p>
            <p class="paragraph">
              Pour 1 000 femmes qui réalisent un dépistage, moins de 2 d’entre elles développeront un cancer entre 2 dépistages.
            </p>
            <p class="paragraph"><i>Sources :</i></p>
            <ol>
              <li>
                <a target="_blank" href="https://www.ameli.fr/assure/sante/themes/cancer-sein/comprendre-cancer-sein"
                  >ameli.fr</a
                >
              </li>
              <li>
                <a target="_blank" href="https://www.santepubliquefrance.fr/maladies-et-traumatismes/cancers/cancer-du-sein"
                  >santepubliquefrance.fr</a
                >
              </li>
              <li>
                <a target="_blank" href="https://www.ligue-cancer.net/questce-que-le-cancer/les-types-de-cancer/cancer-du-sein"
                  >ligue-cancer.net</a
                >
              </li>
              <li>
                <a target="_blank"
                  href="https://www.ligue-cancer.net/sites/default/files/brochures/depliant_depistage_du_cancer_du_sein_selon_lage_et_le_niveau_de_risque_.pdf"
                  >ligue-cancer.net (dépliant dépistage du cancer du sein)</a
                >
              </li>
            </ol>
  </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
