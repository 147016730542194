/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import { RightOutlined } from '@ant-design/icons';
import { Carousel, Col, Image, Row, Typography } from 'antd';
import GGButton from 'components/buttons/GGButton';
import CardIconText from 'components/cards/CardIconText';
import CaregiverAvatarList from 'components/lists/CaregiverAvatarList';
import Logo from 'components/navigation/Logo';
import AppColors from 'config/AppColors';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';

const { Title, Paragraph, Text } = Typography;

const useStyles = createUseStyles({
  carousel: {
    backgroundColor: AppColors.backgroundLightGrey,
    background: `linear-gradient(0deg, ${AppColors.backgroundLightGrey} 0%, white 100%)`,
    backgroundSize: 'auto 100px',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
  },
  carouselPageContainer: {
    display: 'flex !important',
    height: 'calc(100dvh - env(safe-area-inset-top))',
  },
  carouselPage: {
    display: 'flex !important',
    flex: 1,
    flexDirection: 'column',
  },
  carouselPageSection: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
  },
  carouselPageSectionFullPage: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    flex: 1,
  },
  carouselPageSectionFullPageNoGrow: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    flex: 0,
  },
  carouselPageSectionFullPageScroll: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    flex: 1,
    overflow: 'scroll',
  },
  carouselPageSectionBottom: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 50,
  },
  carouselTitle: {},
  appName: {
    color: AppColors.main,
  },
  textStrongGreen: {
    color: `${AppColors.main} !important`,
  },
  contentImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentImage: {
    width: '60% !important',
  },
  cardColumn: {
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
  },
  secondRow: {
    marginTop: 16,
  },
  dots: {
    bottom: '20px !important',

    '& li > button': {
      backgroundColor: `${AppColors.backgroundDarkGrey} !important`,
    },
    '& li.slick-active > button': {
      backgroundColor: `${AppColors.main} !important`,
    },
  },
});

export default function OnboardingPage() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();

  const carousel = useRef(null);

  const goToPage = (route: string) => {
    navigate(route);
    amplitude.track('Onboarding end choice', {
      choice: route,
    });
  };

  const nextPage = (isLastPage: boolean) => {
    if (isLastPage) {
      goToPage(routes.home.route);
      amplitude.track('Onboarding end choice', {
        choice: 'home',
      });
    } else {
      // Display the next page
      (carousel?.current as any)?.next();
    }
  };

  const carouselPages = [
    // Page 1
    {
      content: (
        <div>
          <Paragraph>
            <Text>
              {t('onboarding.carousel.pages.one.text1start')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.one.text1Bold1')}
              </Text>
              {t('onboarding.carousel.pages.one.text1middle')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.one.text1Bold2')}
              </Text>
              {t('onboarding.carousel.pages.one.text1end')}
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              {t('onboarding.carousel.pages.one.text2start')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.one.text2Bold1')}
              </Text>
              {t('onboarding.carousel.pages.one.text2middle')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.one.text2Bold2')}
              </Text>
            </Text>
          </Paragraph>
        </div>
      ),
      scrollableContent: (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CaregiverAvatarList />
        </div>
      ),
    },

    // Page 2
    {
      scrollableContent: (
        <div>
          <Paragraph>
            <Text>
              {t('onboarding.carousel.pages.two.textStart')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.two.textBold1')}
              </Text>
              {t('onboarding.carousel.pages.two.textMiddle1')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.two.textBold2')}
              </Text>
              {t('onboarding.carousel.pages.two.textMiddle2')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.two.textBold3')}
              </Text>
              {t('onboarding.carousel.pages.two.textMiddle3')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.two.textBold4')}
              </Text>
            </Text>
          </Paragraph>
          <div className={styles.contentImageContainer}>
            <Image
              alt="Blog post samples"
              src={require('../assets/onboarding-blog-posts.png')}
              preview={false}
              rootClassName={styles.contentImage}
            />
          </div>
        </div>
      ),
    },

    // Page 3
    {
      scrollableContent: (
        <div>
          <Paragraph>
            <Text>
              {t('onboarding.carousel.pages.three.textStart')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.three.textBold1')}
              </Text>
              {t('onboarding.carousel.pages.three.textMiddle1')}
              <Text strong className={styles.textStrongGreen}>
                {t('onboarding.carousel.pages.three.textBold2')}
              </Text>
              {t('onboarding.carousel.pages.three.textEnd')}
            </Text>
          </Paragraph>
          <div className={styles.contentImageContainer}>
            <Image
              alt="Caregivers list"
              src={require('../assets/onboarding-caregivers-list.png')}
              preview={false}
              rootClassName={styles.contentImage}
            />
          </div>
        </div>
      ),
    },

    // Page 4
    {
      scrollableContent: (
        <div>
          <Row gutter={[16, 16]} justify="center">
            <Col span={12} className={styles.cardColumn}>
              <CardIconText
                icon={require('../assets/chat-light.png')}
                buttonText={t('onboarding.carousel.pages.four.cards.questions')}
                onClick={() => goToPage(routes.newChat.route)}
              />
            </Col>
            <Col span={12} className={styles.cardColumn}>
              <CardIconText
                icon={require('../assets/caregivers-light.png')}
                buttonText={t('onboarding.carousel.pages.four.cards.check')}
                onClick={() => goToPage(routes.newChatCheckup.route)}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" className={styles.secondRow}>
            <Col span={12} className={styles.cardColumn}>
              <CardIconText
                icon={require('../assets/book-light.png')}
                buttonText={t('onboarding.carousel.pages.four.cards.learn')}
                onClick={() => goToPage(routes.info.route)}
              />
            </Col>
            <Col span={12} className={styles.cardColumn}>
              <CardIconText
                icon={require('../assets/experts-woman-light.png')}
                buttonText={t('onboarding.carousel.pages.four.cards.expert')}
                onClick={() => goToPage(routes.caregiversDirectory.route)}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const renderOnboardingCarouselPageTemplate = (
    index: number,
    children: { content?: React.ReactElement; scrollableContent?: React.ReactElement },
  ) => {
    const isLastPage = index === carouselPages.length - 1;
    const buttonType = isLastPage ? 'default' : 'primary';
    const buttonText = isLastPage ? t('onboarding.carousel.buttons.end') : t('onboarding.carousel.buttons.next');

    return (
      <div className={styles.carouselPageContainer}>
        <div className={styles.carouselPage}>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Logo size="large" />
            </Col>
          </Row>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>
                {t('onboarding.carousel.title')}
                <span className={styles.appName}>{t('appName')}</span>
                {t('onboarding.carousel.titleEnd')}
              </Title>
              <Paragraph>
                <span>{t('appTagline')}</span>
              </Paragraph>
            </Col>
          </Row>
          {children.content && (
            <Row className={styles.carouselPageSectionFullPageNoGrow}>
              <Col>{children.content}</Col>
            </Row>
          )}
          {children.scrollableContent && (
            <Row className={styles.carouselPageSectionFullPageScroll}>
              <Col>{children.scrollableContent}</Col>
            </Row>
          )}
          <Row className={styles.carouselPageSectionBottom}>
            <Col>
              <GGButton
                type={buttonType}
                onClick={() => nextPage(isLastPage)}
                icon={<RightOutlined />}
                iconPosition="end"
                size="large"
              >
                {buttonText}
              </GGButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <Carousel
      ref={carousel}
      className={styles.carousel}
      adaptiveHeight
      infinite={false}
      dots={{ className: styles.dots }}
    >
      {carouselPages.map((page, index) => renderOnboardingCarouselPageTemplate(index, page))}
    </Carousel>
  );
}
