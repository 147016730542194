import { ApiManager } from './ApiManager';
import { ConversationDTO, ConversationWithMessagesDTO } from './models/ConversationDTO';

export type ApiPatientConversationsGetPatientConversationsListResult = ConversationDTO[];
export type ApiPatientConversationsGetPatientConversationsMessageListParams = {
  conversationId: string;
};
export type ApiPatientConversationsGetPatientConversationsMessageListResult = ConversationWithMessagesDTO;
export type ApiPatientConversationsPostMessageToPatientConversationsParams = {
  conversationId: string;
  messageText: string;
};
export type ApiPatientConversationsPostMessageToPatientConversationsResult = {
  messageText: string;
};
export type ApiPatientConversationsCreatePatientConversationsParams = {
  initialMessageText: string;
};
export type ApiPatientConversationsCreatePatientConversationsResult = {
  id: string;
};

export default class ApiPatientConversationsManager {
  static async getPatientConversationsList(): Promise<ApiPatientConversationsGetPatientConversationsListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/patient-conversations/`);

    return response.data as ApiPatientConversationsGetPatientConversationsListResult;
  }

  static async getPatientConversationsMessageList(
    params: ApiPatientConversationsGetPatientConversationsMessageListParams,
  ): Promise<ApiPatientConversationsGetPatientConversationsMessageListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/patient-conversations/${params.conversationId}/`);

    return response.data as ApiPatientConversationsGetPatientConversationsMessageListResult;
  }

  static async postMessageToPatientConversations(
    params: ApiPatientConversationsPostMessageToPatientConversationsParams,
  ): Promise<ApiPatientConversationsPostMessageToPatientConversationsResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/patient-conversations/${params.conversationId}/send/`, {
      message_text: params.messageText,
    });

    return {
      messageText: response.data.message_text,
    };
  }

  static async createPatientConversations(
    params: ApiPatientConversationsCreatePatientConversationsParams,
  ): Promise<ApiPatientConversationsCreatePatientConversationsResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/patient-conversations/`, {
      initial_message_text: params.initialMessageText,
    });

    return response.data;
  }

  static async markConversationAsRead(conversationId: string): Promise<void> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(`/api/patient-conversations/${conversationId}/mark-as-read/`);

    return response.data as void;
  }
}
