import { SendOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Form, Input, Space } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { conversationActions } from 'store/conversations';
import { selectCurrentConversationId, selectCurrentInputText } from 'store/conversations/selectors';

const { TextArea } = Input;

export type MessageInputProps<T = any> = {
  disabled?: boolean;
  sendMessage: (messageText: string) => Promise<T>;
  onMessageSent: (result: T) => any;
};

const useStyles = createUseStyles({
  container: {
    zIndex: 9,
    padding: 13,
    boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1)',
  },
  messageInput: {
    marginInlineEnd: '10px !important',
  },
  sendButton: {
    '& .ant-btn-icon': {
      marginLeft: 2,
    },
  },
});

const MessageInput: React.FC<MessageInputProps> = ({ disabled = false, sendMessage, onMessageSent }) => {
  // State
  const [inputValue, setInputValue] = useState<string>();

  // Hooks
  const styles = useStyles();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const dispatch = useDispatch();

  // Selector
  const currentConversationId = useSelector(selectCurrentConversationId);
  const currentInputText = useSelector(selectCurrentInputText);

  const saveInputTextValueInStore = (text?: string) => {
    dispatch(
      conversationActions.updateConversationInputText({
        inputText: text,
      }),
    );
  };

  const saveInputText = (text?: string) => {
    if (currentConversationId) {
      // Existing conversation, save the text in Redux store
      saveInputTextValueInStore(text);
    } else {
      // New conversation (= no conversation id), save the text in local state
      setInputValue(text);
    }
  };

  const { loading, run } = useRequest((messageText: string) => sendMessage(messageText), {
    manual: true,
    onSuccess: result => {
      // Reset the form
      form.resetFields();

      // Reset the input text stored in local state or Redux store
      saveInputText(undefined);

      // Trigger callback on message sent
      onMessageSent(result);
    },
    onError: () => {
      api.error({
        message: t('chats.list.errors.sendMessage'),
      });
    },
  });

  const onFinish = (values: any) => {
    run(values.message);
  };

  // Get the input text, either from the Redux store or local state
  const input = currentInputText || inputValue;
  // A text is present and no empty text
  const isValidInputValue = !!(input && input.length > 0);

  return (
    <Form
      className={styles.container}
      layout="inline"
      form={form}
      onFinish={onFinish}
      fields={[
        {
          name: ['message'],
          value: currentInputText || inputValue,
        },
      ]}
    >
      {context}
      <Form.Item
        className={styles.messageInput}
        style={{ flex: 1 }}
        name="message"
        rules={[{ required: true, message: '' }]}
      >
        <TextArea
          placeholder={t('chats.fields.message.placeholder')}
          size="large"
          autoComplete="off"
          autoSize={{ minRows: 1, maxRows: 5 }}
          onChange={e => saveInputText(e.target.value)}
        />
      </Form.Item>

      <Form.Item className={styles.sendButton} wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <GGButton
            type="primary"
            htmlType="submit"
            size="large"
            icon={<SendOutlined />}
            disabled={loading || disabled || !isValidInputValue}
            loading={loading}
            shape="circle"
          />
        </Space>
      </Form.Item>
    </Form>
  );
};
export default MessageInput;
