/* eslint-disable react/jsx-no-useless-fragment */
import { useRequest } from 'ahooks';
import { Avatar, Modal, Tag } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import AppColors from 'config/AppColors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ApiRegisteredCaregiversManager from 'services/api/ApiRegisteredCaregiversManager';
import { CaregiverDTO } from 'services/api/models/CaregiverDTO';
import defaultCaregiverPhoto from '../../assets/experts-man-dark.png';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    marginTop: '-15px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    height: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: AppColors.backgroundGrey,
    overflowY: 'scroll',
  },
  filterSelect: {
    height: 45,
  },
  list: {
    padding: 0,
    width: '100%',
  },
  item: {},
  detailsContainer: {
    display: 'flex',
    backgroundColor: AppColors.card.background,
    borderRadius: 8,
    flex: 1,
    flexDirection: 'column',
    padding: 8,
  },
  expertise: {
    fontStyle: 'italic',
  },
  profileCard: {
    textAlign: 'center',
    margin: '10px auto',
  },
  avatar: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  fullName: {
    fontSize: '1.5em',
    margin: '10px 0',
    color: '#333',
  },
  bio: {
    fontSize: '0.9em',
    color: '#666',
    margin: '5px 0',
  },
  address: {
    fontSize: '0.9em',
    color: '#666',
    margin: '5px 0',
  },
  phone: {
    fontSize: '0.9em',
    color: '#666',
    margin: '5px 0',
  },
  groups: {
    marginTop: '15px',
  },
  groupBadge: {
    display: 'inline-block',
    backgroundColor: '#f0f0f0',
    color: '#333',
    padding: '5px 10px',
    borderRadius: '20px',
    margin: '5px',
    fontSize: '0.8em',
  },
});

export type CaregiverProfileModalProps = {
  caregiverId: string;
  open?: boolean;
  onClose?: () => void;
};

const CaregiverProfileModal: React.FC<CaregiverProfileModalProps> = ({
  caregiverId,
  open = false,
  onClose = undefined,
}) => {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const [api, context] = useNotification();

  // State
  const [isModalOpen, setIsModalOpen] = useState(open);

  const { data: expert, loading } = useRequest(
    () => ApiRegisteredCaregiversManager.getRegisteredCaregiver({ caregiverId }),
    {
      onError: () => {
        api.error({
          message: t('caregiverProfile.errors.fetch'),
        });
      },
    },
  );

  const handleOk = () => {
    setIsModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const displayAddressChunks = (caregiver: CaregiverDTO) =>
    caregiver.address_street || caregiver.address_zip_code || caregiver.address_city;
  const displayAddressChunksBreakline = (caregiver: CaregiverDTO) =>
    caregiver.address_street && (caregiver.address_zip_code || caregiver.address_city);

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      loading={loading}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('global.buttons.close')}
      closable={false}
    >
      <div className={styles.pageContainer}>
        {context}
        {expert && (
          <div className={styles.profileCard}>
            <Avatar className={styles.avatar} src={expert.user.photo_url || defaultCaregiverPhoto} alt="profile" />
            <h2 className={styles.fullName}>{expert.caregiver.display_name}</h2>
            <h3 className={styles.bio}>{expert.caregiver.bio}</h3>
            {displayAddressChunks(expert.caregiver) && (
              <p className={styles.address}>
                {expert.caregiver.address_street}
                {displayAddressChunksBreakline(expert.caregiver) && <br />}
                {(expert.caregiver.address_zip_code || expert.caregiver.address_city) && (
                  <>
                    {expert.caregiver.address_zip_code} {expert.caregiver.address_city}
                  </>
                )}
              </p>
            )}
            {expert.caregiver.phone_number && <p className={styles.phone}>{expert.caregiver.phone_number}</p>}
            <div className={styles.groups}>
              {expert.caregiver.caregiver_groups.map(group => (
                <Tag key={group.id} className={styles.groupBadge}>
                  {group.name}
                </Tag>
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default CaregiverProfileModal;
