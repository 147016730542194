import {
  CustomerServiceOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Typography } from 'antd';
import BackButton from 'components/buttons/BackButton';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import type { MenuProps } from 'antd';
import useLogout from 'hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../services/RouteService';

const { Title } = Typography;

export type ChatsHeaderProps = {
  title: string;
  backButton?: boolean;
  onBackButtonClicked?: () => void;
};

const useStyles = createUseStyles({
  container: {
    paddingLeft: 13,
    paddingRight: 13,
    display: 'flex',
    justifyContent: 'space-between',
  },
  columnBackButton: {
    marginRight: 10,
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    marginTop: 15,
    paddingRight: 13,
    color: `${AppColors.text.header} !important`,
  },
  menuDropdownIcon: {
    fontSize: 25,
    marginRight: 10,
    color: `${AppColors.text.header} !important`,
  },
  menuDropdown: {
    '& .ant-dropdown-menu-item': {
      padding: '12px 22px !important',
    },
  },
  logoutMenuItem: {
    color: AppColors.colorTextBase,
    padding: 0,
    display: 'flex',
    height: 30,
    justifyContent: 'space-between',
    '& .danger-icon': {
      color: `${AppColors.status.error} !important`,
    },
  },
});

const PageTopBar: React.FC<ChatsHeaderProps> = ({ title, backButton = false, onBackButtonClicked = undefined }) => {
  // Hooks
  const styles = useStyles();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { isSmallScreen } = useIsSmallScreen();
  const { t } = useTranslation();

  const openRoute = (route: string) => {
    navigate(route);
  };

  const settingsMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button type="link" className={styles.logoutMenuItem} onClick={() => openRoute(routes.onboarding.route)}>
          {t('menu.onboarding')} <QuestionCircleOutlined style={{ marginLeft: '20px' }} />
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" className={styles.logoutMenuItem} onClick={() => openRoute(routes.faq.route)}>
          {t('menu.moreInfo')} <InfoCircleOutlined style={{ marginLeft: '20px' }} />
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <a className={styles.logoutMenuItem} href={`mailto:${t('global.support.supportEmailWithSubject')}`}>
          {t('menu.support')} <CustomerServiceOutlined style={{ marginLeft: '20px' }} />
        </a>
      ),
    },
    {
      key: '4',
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <a
          className={styles.logoutMenuItem}
          onClick={() => {
            logout();
            navigate(routes.login.route, { replace: true });
          }}
        >
          {t('menu.logout')} <LogoutOutlined className="danger-icon" style={{ marginLeft: '20px' }} />
        </a>
      ),
    },
  ];

  return (
    <Row className={styles.container}>
      {backButton && (
        <Col className={styles.columnBackButton}>
          <BackButton
            onClick={() => {
              if (onBackButtonClicked) {
                onBackButtonClicked();
              }
            }}
          />
        </Col>
      )}
      <Title level={2} className={styles.title}>
        {title}
      </Title>
      {!backButton && isSmallScreen && (
        <Dropdown overlayClassName={styles.menuDropdown} menu={{ items: settingsMenuItems }}>
          <SettingFilled className={styles.menuDropdownIcon} onClick={e => e.preventDefault()} />
        </Dropdown>
      )}
    </Row>
  );
};
export default PageTopBar;
