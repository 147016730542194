module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Les principaux symptômes du SOPK
          </h1>

          <div class="date-modified color-secondary">Publié le 4 juin 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Ces symptômes se manifestent de manière variée selon les femmes : certains peuvent être très forts et perturbants,
              tandis que d'autres peuvent être légers ou absents. Voici les 10 principaux signes qui devraient vous inciter à
              consulter un médecin spécialisé.
            </p>
            <ol>
              <li>
                <b>Problèmes d'ovulation</b>: les troubles de l'ovulation sont souvent le premier signe d'alerte du SOPK. Cela
                peut se traduire par des cycles menstruels rares (dysovulation) ou absents (anovulation), ainsi que par une
                aménorrhée ou des cycles menstruels irréguliers de plus de 40 jours.
              </li>
              <li>
                <b>Infertilité et désir de grossesse:</b>&nbsp;le SOPK est la principale cause d'infertilité chez les femmes. Cela
                peut se manifester par des difficultés à concevoir ou une infertilité totale. Environ la moitié des femmes
                atteintes de SOPK rencontrent des problèmes de fertilité. De plus, le SOPK peut entraîner des complications
                pendant la grossesse, telles qu'un risque accru de diabète gestationnel, d'hypertension artérielle, de
                prééclampsie, de prématurité et de faible poids à la naissance.
              </li>
              <li>
                <b>Pilosité excessive ou hirsutisme:</b>&nbsp;les femmes atteintes de SOPK peuvent présenter une production
                anormalement élevée d'androgènes, en particulier de testostérone, ce qui entraîne une hyperandrogénie. Cela se
                traduit souvent par une pilosité excessive dans des zones telles que le menton, la lèvre supérieure, la poitrine,
                le dos et les fesses. Environ 70 % des femmes atteintes de SOPK souffrent de ce problème, qui peut être gênant au
                quotidien.
              </li>
              <li>
                <b>Perte de cheveux:</b>&nbsp;une autre conséquence de l'hyperandrogénie est la perte de cheveux, qui peut se
                produire au sommet du crâne et aux tempes chez les femmes atteintes de SOPK.
              </li>
              <li>
                <b>Acné:</b>&nbsp;l'excès de testostérone lié au SOPK peut également provoquer de l'acné persistante au-delà de
                l'adolescence.
              </li>
              <li>
                Modification de la voix Un taux élevé de testostérone peut également modifier le timbre de la voix, le rendant
                plus grave.
              </li>
              <li>
                <b>Hypoplasie mammaire:</b>&nbsp;certaines femmes atteintes de SOPK peuvent présenter une hypoplasie mammaire,
                caractérisée par une faible taille de la poitrine. Pendant la grossesse, cette condition peut entraîner un faible
                développement mammaire et une production de lait insuffisante.
              </li>
              <li>
                <b>Acanthosis nigricans:</b>&nbsp;le SOPK peut être associé à un assombrissement et un épaississement de la peau
                dans des zones telles que les aisselles, la nuque, l'aine et les plis cutanés, en raison d'une résistance à
                l'insuline.
              </li>
              <li>
                <b>Prise de poids:</b>&nbsp;de nombreuses femmes atteintes de SOPK ont un poids corporel élevé en raison de
                l'hyperandrogénie, ce qui peut rendre la perte de poids difficile. Une modification du mode de vie et de
                l'alimentation ainsi que l'exercice physique peuvent influencer la fertilité.
              </li>
              <li>
                <b>Résistance à l'insuline et diabète:</b>&nbsp;le SOPK peut également entraîner une résistance à l'insuline,
                conduisant à une accumulation de glucose dans le sang et augmentant le risque de pré-diabète ou de diabète de type
                2.
              </li>
            </ol>
          </div>


        </div>
      </section>
    </div>
  </body>
</html>
`;
