import { Card, Image } from 'antd';
import GGButton from 'components/buttons/GGButton';
import { createUseStyles } from 'react-jss';

export type CardIconTextProps = {
  icon: string;
  buttonText: string;
  onClick: () => void;
};

const useStyles = createUseStyles({
  card: {
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    width: '90%',
    cursor: 'pointer',
  },
  cardIconContainer: {
    flex: 1,
    alignContent: 'top',
  },
  cardIcon: {
    minWidth: 80,
    maxWidth: 80,
  },
  cardButtonText: {
    textWrap: 'wrap',
    width: '100%',
    backgroundColor: 'unset !important',
    padding: 0,
  },
});

const CardIconText: React.FC<CardIconTextProps> = ({ icon, buttonText, onClick }) => {
  // Hooks
  const styles = useStyles();

  return (
    <Card bordered={false} className={styles.card} onClickCapture={() => onClick()}>
      <div className={styles.cardIconContainer}>
        <Image src={icon} alt="icon" className={styles.cardIcon} preview={false} />
      </div>
      <GGButton rootClassName={styles.cardButtonText} type="text" onClick={() => onClick()} iconPosition="end">
        {buttonText}
      </GGButton>
    </Card>
  );
};
export default CardIconText;
