module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">

        <div class="container container-p--large">
          <h1 class="post-title">
          Infertilité: tous concernés !
          </h1>

          <div class="date-modified color-secondary">Publié le 7 août 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">En France, les constats sont clairs.</p>
            <p class="paragraph">
              <b>L’infertilité gagne du terrain chez les couples et les personnes en âge de procréer&nbsp;:</b>
            </p>
            <ul>
              <li>Un couple sur quatre ne connaissent pas de grossesse après un an de rapports non protégés.</li>
              <li>
                15 à 25 % des couples restent infertiles après un an de rapports non protégés ; 8 à 11 % le sont encore après deux
                ans.
              </li>
              <li>Plus de 60 000 nouveaux couples consultent chaque année pour infertilité.</li>
            </ul>
            <p class="paragraph"><b>Les femmes sont particulièrement touchées&nbsp;:</b></p>
            <ul>
              <li>Plus de 150 000 femmes sont traitées pour infertilité chaque année, soit plus d’une femme sur 100.</li>
              <li>
                Une femme sur dix souffre d’endométriose, une sur huit du syndrome des ovaires polykystiques, et de plus en plus
                de femmes ont une insuffisance ovarienne précoce avant 40 ans.
              </li>
              <li>40 % des femmes avec de l’endométriose ont des problèmes de fertilité.</li>
              <li>Le recours aux traitements de l’infertilité a augmenté de 24 % chez les femmes de 34 ans ou plus.</li>
            </ul>
            <p class="paragraph"><b>Les hommes continuent de perdre leurs spermatozoïdes en quantité et en qualité&nbsp;:</b></p>
            <ul>
              <li>La qualité et la quantité des spermatozoïdes ne cessent de baisser.</li>
            </ul>
            <p class="paragraph"><b>Les naissances via l’AMP continuent de grimper&nbsp;:</b></p>
            <ul>
              <li>
                3,6 % des enfants, soit 1 enfant sur 28, sont nés en 2019 grâce à l’aide de la médecine procréative. Ce chiffre ne
                fait qu’augmenter. Sans compter tous les enfants nés en France grâce à une AMP réalisée à l’étranger et qui ne
                sont pas comptabilisés comme bébés AMP.
              </li>
              <li>
                Il est évident que la dégradation de la santé environnementale impacte directement la santé reproductive et
                globale.&nbsp;Et ce, dès la gestation et la petite enfance.
              </li>
            </ul>
            <p class="paragraph">
              L’infertilité est devenue une question médicale majeure de santé publique qui va au-delà de la médecine et concerne
              plus largement l’avenir de notre société. «&nbsp;<b>Infertilité : tous concernés !&nbsp;</b>»
            </p>
            <h2>L'avis du directeur médical de Gynger</h2>
            <p class="paragraph">
              “Vous n’êtes pas seul(e)(s) ! Des solutions existent et peuvent être envisagées au cas par cas. Ne négligez pas le
              soutien psychologique et l’importance du soutien mutuel avec votre partenaire. Nous avons vu de nombreuses patientes
              qui étaient déclarées “infertiles” et qui sont parvenues à avoir un enfant, ne perdez pas espoir ! Parlez-en
              librement à votre sage-femme referente sur la messagerie Gynger, rejoignez nos groupes de soutien entre patientes ou
              contacter les associations mobilisées sur la fertilité et la PMA.”
            </p>
            <p class="paragraph"><b>Quentin MARQUET, pharmacien et directeur médical de Gynger</b></p>
            <p class="paragraph"><i>Sources :</i></p>
            <p class="paragraph">
              INED Khaoula Ben Messaoud, Jean Boyer, Elise de la Rochebrochard «&nbsp;un recours aux traitements de l’infertilité
              de plus en plus tardifs&nbsp;», 14 janvier 2021
            </p>
            <p class="paragraph">INSERM/INED 01/2021 «Un recours aux traitements de l’infertilité de +en+ tardifs&nbsp;»</p>
            <p class="paragraph">
              <a
                target="_blank"
                href="https://academic.oup.com/humupd/advance-article/doi/10.1093/humupd/dmac035/6824414?searchresult=1"
                >https://academic.oup.com/humupd/advance-article/doi/10.1093/humupd/dmac035/6824414?searchresult=1</a
              >
            </p>
            <p class="paragraph">
              <a
                target="_blank"
                href="https://www.lemonde.fr/planete/article/2022/11/15/le-declin-de-la-fertilite-masculine-est-mondial-et-s-accelere_6149933_3244.html"
                >https://www.lemonde.fr/planete/article/2022/11/15/le-declin-de-la-fertilite-masculine-est-mondial-et-s-accelere_6149933_3244.html</a
              >
            </p>
            <p class="paragraph">Rapport ABM 2019 et INSEE «&nbsp;naissances et taux de natalité 2020&nbsp;»</p>
            <p class="paragraph">
              INSERM «&nbsp;Les conséquences d’une exposition précoce aux perturbateurs endocriniens sur les fonctions
              reproductives&nbsp;» 18 novembre 2021
            </p>
          </div>

      </section>
    </div>
  </body>
</html>
`;
