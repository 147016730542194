export default class ScrollUtil {
  static scrollToTop(component?: any) {
    (component || window).scrollTo(0, 0);
  }

  static scrollTo(component: any, yPosition: number) {
    (component || window).scrollTo(0, yPosition);
  }

  static getScrollPosition(component?: any) {
    return (component || window).scrollTop;
  }
}
