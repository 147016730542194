/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-unescaped-entities */
import { LogoutOutlined } from '@ant-design/icons';
import { Button, Image, Typography } from 'antd';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import useLogout from 'hooks/useLogout';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { conversationActions } from 'store/conversations';
import { routes } from '../../services/RouteService';

const useStyles = createUseStyles({
  navBarContainer: {
    background: `${AppColors.offWhite} !important`,
    alignItems: 'center',
    padding: 10,
    paddingBottom: 'calc(env(safe-area-inset-bottom) / 2 + 20px)',
  },
  buttonsContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  buttonsCenterContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  container: {
    '& span.ant-btn-icon': {
      alignSelf: 'center',
    },
    '& span': {
      color: `${AppColors.text.navigation} !important`,
    },
  },
  buttonMobile: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileText: {
    fontSize: 12,
  },
  logoutIcon: {
    marginTop: 4,
    fontSize: 26,
  },
});

export default function BottomNavBar() {
  // Hooks
  const navigate = useNavigate();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { isSmallScreen } = useIsSmallScreen();
  const location = useLocation();

  const onClick = (route: string) => {
    if (route === routes.logout.route) {
      logout();
      navigate(routes.login.route, { replace: true });
    } else {
      navigate(route, { replace: true });
    }
  };

  function renderIcon(src: any, size: number) {
    return <Image alt="icon" height={size} width={size} preview={false} src={src} />;
  }

  function renderNavButton(params: {
    iconSrc: any;
    selectedIconSrc: any;
    routeInfo: { key: string; route: string; label: string };
    onClickFn?: () => void;
    isCurrentRoute: boolean;
    isCenterIcon?: boolean;
  }) {
    const centerButtonStyle = {
      borderWidth: '10px',
      borderColor: AppColors.offWhite,
      borderRadius: '35px',
      height: 'auto',
      padding: 0,
    };

    const iconSource = params.isCurrentRoute ? params.selectedIconSrc : params.iconSrc;

    return (
      <Button
        className={styles.container}
        type="link"
        icon={!isSmallScreen && renderIcon(iconSource, 35)}
        onClick={() => {
          if (params.onClickFn) {
            params.onClickFn();
          } else {
            onClick(params.routeInfo.route);
          }
        }}
        style={params.isCenterIcon ? centerButtonStyle : {}}
      >
        {isSmallScreen ? (
          <div className={styles.buttonMobile}>
            {renderIcon(iconSource, params.isCenterIcon ? 50 : 30)}
            {params.isCenterIcon ? null : (
              <Typography.Text className={styles.mobileText}>{params.routeInfo.label}</Typography.Text>
            )}
          </div>
        ) : (
          params.routeInfo.label
        )}
      </Button>
    );
  }

  const isNewConversationRoute = location.pathname === routes.newChat.route;
  const isInfoRoute = location.pathname === routes.info.route;
  const isCaregiversDirectoryRoute = location.pathname === routes.caregiversDirectory.route;

  return (
    <div className={styles.navBarContainer}>
      <div className={styles.buttonsCenterContainer}>
        {renderNavButton({
          iconSrc: require('../../assets/chat-light.png'),
          selectedIconSrc: require('../../assets/chat-dark.png'),
          routeInfo: routes.home,
          isCurrentRoute: !(isNewConversationRoute || isInfoRoute || isCaregiversDirectoryRoute),
          onClickFn: () => {
            if (isSmallScreen) {
              navigate(routes.home.routeNoReload, { replace: true });

              // On small screen, reset the current selected conversation to display the "previous" screen
              dispatch(conversationActions.resetCurrentConversation());
            } else {
              navigate(routes.home.routeReload, { replace: true });
            }
          },
        })}
        {renderNavButton({
          iconSrc: require('../../assets/book-light.png'),
          selectedIconSrc: require('../../assets/book-dark.png'),
          routeInfo: routes.info,
          isCurrentRoute: isInfoRoute,
        })}
        {renderNavButton({
          iconSrc: require('../../assets/experts-woman-light.png'),
          selectedIconSrc: require('../../assets/experts-woman-dark.png'),
          routeInfo: routes.caregiversDirectory,
          isCurrentRoute: isCaregiversDirectoryRoute,
        })}
        {!isSmallScreen && (
          <Button className={styles.container} type="link">
            <LogoutOutlined
              className={styles.logoutIcon}
              onClick={() => {
                logout();
                navigate(routes.login.route, { replace: true });
              }}
            />
          </Button>
        )}
      </div>
    </div>
  );
}
