/* eslint-disable global-require */
import { UserOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Form, Input } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import AuthContainer from 'components/containers/AuthContainer';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ApiUsersManager from 'services/api/ApiUsersManager';

const useStyles = createUseStyles({
  textInput: {
    fontSize: 16,
    textTransform: 'lowercase',
  },
});

export default function PasswordResetSendEmailPage() {
  // Hooks
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const styles = useStyles();

  const { loading, run } = useRequest(params => ApiUsersManager.askToResetPassword(params), {
    manual: true,
    onSuccess: () => {
      api.success({
        message: t('passwordReset.success.sendEmail'),
      });
    },
    onError: () => {
      api.error({
        message: t('passwordReset.errors.sendEmail'),
      });
    },
  });

  const onFinish = (values: { email: string }) => {
    run({ email: values.email });
  };

  const validateEmail = (rule: any, value: any, callback: any) => {
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    if (!emailRegex.test(value)) {
      callback(t('loginRegister.fields.email.errors.notEmail'));
    } else {
      callback();
    }
  };

  return (
    <AuthContainer
      title={t('passwordReset.title')}
      description={t('passwordReset.description.sendEmail')}
      mode="passwordReset"
      bottomLinkTo="login"
    >
      {context}
      <Form name="normal_login" onFinish={onFinish} layout="vertical" requiredMark="optional">
        <Form.Item name="email" rules={[{ required: true }, { validator: validateEmail }]}>
          <Input
            prefix={<UserOutlined />}
            autoCapitalize="none"
            placeholder={t('passwordReset.fields.email.placeholder')}
            allowClear
            className={styles.textInput}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: '0px' }}>
          <GGButton block type="primary" htmlType="submit" disabled={loading} loading={loading}>
            {t('passwordReset.actions.sendEmail')}
          </GGButton>
        </Form.Item>
      </Form>
    </AuthContainer>
  );
}
