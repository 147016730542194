import { useRequest } from 'ahooks';
import { List } from 'antd';
import CaregiverAvatar from 'avatar/CaregiverAvatar';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ApiRegisteredCaregiversManager from 'services/api/ApiRegisteredCaregiversManager';

const useStyles = createUseStyles({
  listContainer: {
    marginTop: '2em',
    scrollbarWidth: 'none',
    flex: 1,
    display: 'flex',
  },
});

const CaregiverAvatarList = () => {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();

  const { loading, data } = useRequest(() => ApiRegisteredCaregiversManager.getRegisteredCaregiversList());

  return (
    <div className={styles.listContainer}>
      <List
        itemLayout="horizontal"
        grid={{ gutter: 4, column: 3 }}
        dataSource={data}
        loading={loading}
        renderItem={(item, index) => {
          const firstGroupName =
            item.caregiver.caregiver_groups.length > 0
              ? t(`caregiverGroups.${item.caregiver.caregiver_groups[0].name}`)
              : '';

          return (
            <List.Item>
              <CaregiverAvatar
                key={`avatar-${index}`}
                avatar={item.user.photo_url}
                name={item.caregiver.first_name}
                caption={firstGroupName}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};
export default CaregiverAvatarList;
