import { Skeleton } from 'antd';
import { createUseStyles } from 'react-jss';

export type SkeletonChatsProps = {
  numberOfItems?: number;
};

const useStyles = createUseStyles({
  container: {
    flex: 1,
  },
  subContainer: {
    display: 'grid',
    padding: '0px 15px 0px 15px',
  },
  loaderAvatar: {
    alignItems: 'center',
    display: 'flex',
  },
});

export default function SkeletonChats(props: SkeletonChatsProps = { numberOfItems: 1 }) {
  const { numberOfItems } = props;

  // Hooks
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {[...Array(numberOfItems)].map((value, index) => (
          <Skeleton
            key={`skeleton-${index}`}
            avatar
            active
            title={false}
            paragraph={{ rows: 2 }}
            className={styles.loaderAvatar}
          />
        ))}
      </div>
    </div>
  );
}
