import { ApiManager } from './ApiManager';
import { UserDTO } from './models/UserDTO';

export type ApiUsersGetUsersMeResult = UserDTO;
export type ApiUsersSignUpParams = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  license_code: string;
  tc_and_privacy_policy_consent: boolean;
  personal_information_access_consent: boolean;
  email_notifications_consent: boolean;
};
export type ApiUsersSignUpResult = void;
export type ApiUsersAskToResetPasswordParams = {
  email: string;
};
export type ApiUsersAskToResetPasswordResult = void;
export type ApiUsersResetPasswordParams = {
  uid: string;
  token: string;
  new_password: string;
  re_new_password: string;
};
export type ApiUsersResetPasswordResult = {
  // Errors
  new_password: string[];
};
export type ApiUsersRegisterFCMTokenParams = {
  token: string;
};

export default class ApiUsersManager {
  static async getUsersMe(): Promise<ApiUsersGetUsersMeResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/auth/users/me/`);

    return response.data as ApiUsersGetUsersMeResult;
  }

  static async signUp(params: ApiUsersSignUpParams): Promise<ApiUsersSignUpResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/users/signup/`, params);

    return response.data as ApiUsersSignUpResult;
  }

  static async askToResetPassword(params: ApiUsersAskToResetPasswordParams): Promise<ApiUsersAskToResetPasswordResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/auth/users/reset_password/`, params);

    return response.data as ApiUsersAskToResetPasswordResult;
  }

  static async resetPassword(params: ApiUsersResetPasswordParams): Promise<ApiUsersResetPasswordResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/auth/users/reset_password_confirm/`, params);

    return response.data as ApiUsersResetPasswordResult;
  }

  static async registerFCMToken(params: ApiUsersRegisterFCMTokenParams): Promise<void> {
    const apiManager = ApiManager.getInstance();
    await apiManager.post(`/api/users/fcm-token/register/`, params);
  }

  static async acknowledgeNPS(): Promise<void> {
    const apiManager = ApiManager.getInstance();
    await apiManager.patch(`/api/users/edit/acknowledge_nps_survey/`);
  }
}
