module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Pourquoi devrais-je participer à un programme d’Education Thérapeutique du Patient (ETP) ?
          </h1>

          <div class="date-modified color-secondary">Publié le 8 mai - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              L'Éducation Thérapeutique du Patient (ETP), telle que définie par l'Organisation Mondiale de la Santé (OMS), est un
              processus visant à aider les patients à acquérir ou à maintenir les compétences nécessaires pour gérer au mieux leur
              vie avec une maladie chronique. Intégrée à la prise en charge du patient, l'ETP comprend des activités organisées et
              un soutien psychosocial pour informer et conscientiser les patients sur leur maladie, les soins, l'organisation
              hospitalière, ainsi que sur les comportements liés à la santé et à la maladie.
            </p>
            <p class="paragraph">Les compétences visées par l'ETP comprennent :</p>
            <ul>
              <li>Comprendre la maladie et ses traitements</li>
              <li>Soulager les symptômes</li>
              <li>Gérer les émotions et le stress</li>
              <li>Adapter le mode de vie, notamment l'alimentation et l'activité physique</li>
              <li>Effectuer des gestes de soins</li>
            </ul>
            <p class="paragraph">
              Les programmes d'ETP sont généralement mis en place par une équipe de professionnels de santé formés à cette
              pratique. Ils se déroulent sous forme d'ateliers collectifs ou individuels et suivent un schéma organisé, comprenant
              un Bilan Éducatif Partagé (BEP) ou diagnostic éducatif, des sessions d'ateliers et une évaluation ultérieure pour
              faire le point sur les acquis du patient.
            </p>
            <p class="paragraph">
              En ce qui concerne l'endométriose, plusieurs programmes d'ETP spécifiquement dédiés sont disponibles en France, dans
              des établissements de santé tels que le CHU de Montpellier, le CHU de Nîmes, l'Hôpital Saint Joseph à Marseille,
              etc. Ces programmes abordent diverses thématiques liées à l'endométriose, telles que la compréhension de la maladie,
              les troubles de la fertilité, l'activité physique adaptée, la sexualité, la gestion de la douleur, l'alimentation et
              la gestion du stress.
            </p>
            <p class="paragraph">
              Pour participer à ces programmes, il est généralement nécessaire de contacter le coordinateur du programme ou son
              secrétariat. Certains programmes peuvent exiger un justificatif du diagnostic pour intégrer et participer au
              programme. Il est également possible pour les conjoints de participer à certaines sessions, notamment celles portant
              sur la sexualité, les tabous liés à la pathologie ou la fertilité.
            </p>
            <p class="paragraph">
              Les programmes d'ETP sont organisés sur plusieurs semaines, avec plusieurs sessions par an, afin d'accompagner un
              maximum de patients dans leur parcours de prise en charge de l'endométriose.
            </p>
          </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
