module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            C’est quoi le lien entre endométriose et fatigue chronique ?
          </h1>

          <div class="date-modified color-secondary">Publié le 18 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              L'inflammation joue un rôle crucial dans le contexte de l'endométriose. Les lésions endométriales déclenchent une
              inflammation persistante, stimulant ainsi le système immunitaire. Celui-ci réagit en libérant des protéines appelées
              cytokines, qui participent notamment au processus de guérison. Cependant, ces cytokines sont également associées à
              la fatigue, ce qui pourrait expliquer le sentiment d'épuisement constant chez les personnes atteintes
              d'endométriose. C'est du moins l'une des hypothèses avancées par les chercheurs à l'origine de l'étude "Fatigue - un
              symptôme de l'endométriose". Cependant, il est important de noter que ces hypothèses demeurent à confirmer, l'étude
              s'étant basée principalement sur des questionnaires, ce qui peut influencer les résultats. De plus, le nombre limité
              d'études de grande envergure sur ce sujet entrave actuellement la validation des pistes identifiées.
            </p>
            <p class="paragraph">
              Parallèlement, le stress lié aux douleurs et aux répercussions de la maladie sur la vie quotidienne, combiné au
              stress habituel, pourrait également contribuer à la fatigue. En situation de stress, le corps réagit en libérant de
              l'adrénaline et du cortisol pour activer la réponse de lutte ou de fuite. Cependant, cette réaction demande de
              l'énergie, pouvant ainsi perturber le cycle hormonal, qui dépend en partie des glandes surrénales. Ce déséquilibre
              hormonal, induit par le stress, perturbe le métabolisme global, entraînant des dysfonctionnements et des
              ralentissements qui contribuent à la fatigue. On désigne souvent cette situation sous le terme de "fatigue
              surrénale".
            </p>
            <p class="paragraph">
              En outre, d'autres facteurs liés à la vie quotidienne avec l'endométriose doivent être pris en compte, tels que :
            </p>
            <ul>
              <li>Une faiblesse hépatique, souvent due à une utilisation fréquente de médicaments</li>
              <li>
                Des carences nutritionnelles, notamment l'anémie, fréquente chez les personnes sujettes à des règles abondantes
              </li>
              <li>
                Des problèmes psychologiques tels que l'anxiété, la dépression ou les traumatismes, qui peuvent entraîner un
                stress chronique favorisant la fatigue surrénale
              </li>
              <li>Des troubles du sommeil, qui sont souvent présents lorsque l'endométriose perturbe le sommeil nocturne</li>
            </ul>
            <h2>Alors comment gérer la fatigue ?</h2>
            <p class="paragraph">
              Étant donné que l'endométriose reste largement méconnue, et encore moins ses implications en termes de fatigue
              chronique, la médecine moderne ne dispose pas encore de solutions miracles pour y remédier. Cependant, cela ne
              signifie pas pour autant qu'il faut baisser les bras et se laisser submerger par l'épuisement. Bien que par moments,
              l'idée puisse sembler tentante.
            </p>
            <p class="paragraph">
              Pour faire face à la fatigue, il est primordial de réduire au maximum l'inflammation dans le corps. De plus, adopter
              un mode de vie sain et équilibré est essentiel. Voici quelques pistes à explorer pour soulager cette fatigue pesante
              :
            </p>
            <ul>
              <li>
                Adopter une alimentation saine et équilibrée en limitant les aliments inflammatoires, voire en suivant un régime
                anti-inflammatoire, comme évoqué précédemment.
              </li>
              <li>Essayer de maintenir des horaires réguliers pour les repas ainsi que pour le sommeil, autant que possible.</li>
              <li>
                Continuer à pratiquer une activité physique, adaptée à ses capacités et ses préférences, qu'il s'agisse de sports
                intenses ou doux, voire en optant pour un programme adapté.
              </li>
              <li>Prendre soin de son foie en réduisant la consommation d'alcool et de sucre.</li>
              <li>
                Trouver des moyens de gérer le stress, l'anxiété, voire le mal-être, à travers le sport, des moments de détente,
                des activités apaisantes, le soutien des proches, voire en consultant un sophrologue ou un psychologue si
                nécessaire.
              </li>
            </ul>
          </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
