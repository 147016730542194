module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Comment évaluer la douleur?
          </h1>

          <div class="date-modified color-secondary">Publié le 28 juin 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              La douleur, sous ses nombreuses formes, est le symptôme le plus courant de l’endométriose et celui qui affecte le
              plus la qualité de vie des femmes atteintes de cette maladie.
            </p>
            <p class="paragraph">
              Évaluer ces douleurs – en termes de localisation, intensité, fréquence et conditions d’apparition – est essentiel
              pour mesurer l'étendue et l'impact des lésions, ainsi que pour mettre en place un traitement adapté et suivre son
              efficacité. Ainsi, tout au long de la maladie et de son traitement, les femmes souffrant d’endométriose doivent
              décrire régulièrement leurs douleurs. Pour faciliter cette évaluation, divers outils, plus ou moins spécifiques,
              permettent d’objectiver les sensations douloureuses. Ces outils sont complétés par des mesures de qualité de vie
              visant à apprécier l’impact de la douleur et d'autres symptômes sur le bien-être des patientes.
            </p>
            <h3>Une expérience personnelle de la douleur</h3>
            <p class="paragraph">
              La douleur est une sensation complexe, à la fois sensorielle et émotionnelle, impliquant des récepteurs présents
              dans tout l’organisme (peau, organes, muscles, os, etc.), des nerfs qui transmettent l’information vers la moelle
              épinière et le cerveau, et des régions cérébrales où elle est analysée, évaluée, et suscite des réactions et des
              émotions.
            </p>
            <p class="paragraph">
              Indépendamment de son origine, la douleur est perçue différemment par chacun. Lorsqu'un stimulus douloureux
              standardisé est appliqué à différentes personnes, les ressentis varient en fonction de leurs particularités
              biologiques (la manière dont leur corps perçoit et transmet la douleur), de leur contrôle sur la cause de la
              douleur, de leur état psychique ou de fatigue, de leur histoire personnelle avec la douleur, de la persistance de
              cette douleur, etc.
            </p>
            <p class="paragraph">
              La douleur étant une sensation subjective, il n'existe pas de dispositif objectif pour mesurer ses caractéristiques
              et son intensité. Son évaluation repose donc sur la capacité de chacun à décrire ses sensations et à utiliser le
              vocabulaire approprié : sourde, vive, pulsatile, avec sensation d’écrasement ou de brûlure, continue, irradiante,
              etc.
            </p>
            <h3>Les différents types de douleur à évaluer en cas d’endométriose</h3>
            <p class="paragraph">L’endométriose se manifeste par une grande variété de douleurs.</p>
            <p class="paragraph">Les principaux symptômes évocateurs d’endométriose sont :</p>
            <ul>
              <li>
                <b>Dysménorrhées</b> : Règles douloureuses intenses (intensité ≥ 8/10), associées à un absentéisme fréquent ou une
                résistance aux analgésiques de première intention (paracétamol ou AINS).
              </li>
              <li><b>Dyspareunies</b> : Douleurs profondes pendant les relations sexuelles.</li>
              <li><b>Douleurs digestives</b> : Notamment à la défécation, plus sévères pendant les règles.</li>
              <li><b>Scapulalgie</b> : Douleur de l’épaule droite liée à une endométriose diaphragmatique.</li>
              <li><b>Douleurs et troubles urinaires</b> : Plus sévères pendant les règles.</li>
            </ul>
            <p class="paragraph">D’autres douleurs, moins caractéristiques de l’endométriose, peuvent prêter à confusion :</p>
            <ul>
              <li><b>Douleurs neuropathiques</b> : Comme les sciatiques.</li>
              <li>
                <b>Douleurs abdominales chroniques non cycliques</b> : Persistant plus de 6 mois sans variation selon le cycle
                menstruel.
              </li>
              <li><b>Douleurs des muscles et des fascias</b> : Douleurs lombaires, périnéales, abdominales, etc.</li>
              <li><b>Névralgies pudendales</b> : Douleurs allant du clitoris à l’anus, aggravées en position assise.</li>
              <li><b>Vulvodynies</b> : Douleurs de la vulve sans cause identifiable, durant plus de 3 mois.</li>
            </ul>
            <p class="paragraph">
              Étant donné la diversité des manifestations douloureuses de l’endométriose et leur chevauchement avec d’autres
              maladies, il est souvent nécessaire d’évaluer séparément ces multiples dimensions pour distinguer les douleurs et
              mieux apprécier l’efficacité des traitements.
            </p>
            <h3>L’évaluation de la douleur lors de l’examen clinique</h3>
            <p class="paragraph">
              Lors de l’examen clinique, le médecin recherche les zones douloureuses (organes génitaux, tube digestif, peau,
              muqueuses, muscles, fascia, organes abdominaux, etc.). L’objectif est de cartographier les zones douloureuses,
              identifier les mouvements déclencheurs et les localisations potentielles de l’endométriose. Par palpation, pression,
              modification des positions et exploration des nerfs, l’examen clinique fournit des informations cruciales pour
              comprendre l’origine des douleurs et permettre un traitement antalgique personnalisé. Cet examen inclut souvent un
              examen vaginal et parfois un examen rectal.
            </p>
            <p class="paragraph">
              Pour aider le praticien, il peut être utile de tenir un journal des symptômes douloureux avant l’examen :
              localisation, durée, intensité, éléments déclencheurs, efficacité des traitements, etc.
            </p>
            <h3>Les uutils d’évaluation de la douleur</h3>
            <p class="paragraph">
              De nombreux outils permettent d’évaluer la douleur. Certains sont destinés à l'auto-évaluation par le patient,
              reposant sur des questionnaires ou des échelles visuelles, tandis que d’autres sont utilisés par le personnel
              soignant pour les patients incapables d’évaluer leur douleur eux-mêmes (hétéro-évaluation).
            </p>
            <p class="paragraph">
              Les outils les plus utilisés et les plus simples sont des échelles quantitatives. L’échelle numérique simplifiée
              (ENS) permet à la patiente d’exprimer l’intensité de chaque type de douleur sur une échelle de 0 à 10. L’échelle
              visuelle analogique (EVA) utilise une réglette horizontale allant de « pas de douleur » à « douleur maximale
              imaginable », où la patiente positionne un curseur. L’EVA et l’ENS sont particulièrement adaptées pour les patientes
              atteintes d’endométriose.
            </p>
            <p class="paragraph">
              Des outils plus précis, comme le QDSA (Questionnaire Douleur de l’hôpital Saint-Antoine), explorent en détail les
              sensations douloureuses. Le questionnaire Biberoglu et Behrman (B&amp;B) évalue les douleurs pelviennes, les
              dysménorrhées, les dyspareunies, la sensibilité pelvienne et la mobilité de l’utérus. D'autres outils, comme le
              questionnaire DN4, évaluent les douleurs neuropathiques.
            </p>
            <h3>Conséquences de la douleur sur la qualité de vie</h3>
            <p class="paragraph">
              Évaluer la douleur, ce n’est pas seulement en mesurer l’intensité, mais aussi comprendre son impact sur la qualité
              de vie. Les échelles de qualité de vie, telles que le SF-36 et l’EHP-30 (ou EHP-5), bien que non spécifiques à
              l’endométriose, aident à évaluer l’efficacité des traitements.
            </p>
            <h3>Conclusion</h3>
            <p class="paragraph">
              La douleur liée à l’endométriose présente de multiples aspects qui nécessitent une évaluation détaillée pour une
              prise en charge adaptée. Il est crucial de distinguer les douleurs liées à l’endométriose de celles d’autres
              maladies associées. Une description précise des douleurs, de leur intensité et de leur impact sur la vie
              quotidienne, est indispensable pour un suivi objectif et régulier de l’effet des traitements tout au long de la
              maladie.
            </p>
          </div>

        </div>
      </section>
    </div>
  </body>
</html>
`;
