import { Image } from 'antd';
import { createUseStyles } from 'react-jss';

export type BackButtonProps = {
  onClick: () => void;
};

const useStyles = createUseStyles({
  button: {
    cursor: 'pointer',
  },
});

export default function BackButton(props: BackButtonProps) {
  const { onClick } = props;

  const styles = useStyles();

  return (
    <Image
      alt="back-button"
      height={30}
      preview={false}
      // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
      src={require('../../assets/chevron-left.png')}
      onClick={() => onClick()}
      className={styles.button}
    />
  );
}
