/* eslint-disable global-require */
import { useRequest } from 'ahooks';
import useNotification from 'antd/es/notification/useNotification';
import LoginRegisterForm from 'components/forms/LoginRegisterForm';
import useAuthGuard from 'hooks/useAuthGuard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiUsersManager, { ApiUsersSignUpParams } from 'services/api/ApiUsersManager';

export default function RegisterPage() {
  // Hooks
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const { canCallApi, getAccessToken } = useAuthGuard();
  const navigate = useNavigate();

  useEffect(() => {
    if (canCallApi) {
      navigate(routes.onboarding.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCallApi]);

  const { loading, run } = useRequest(params => ApiUsersManager.signUp(params), {
    manual: true,
    onSuccess: (_, params) => {
      getAccessToken({ email: params[0].email, password: params[0].password });
    },
    onError: () => {
      api.error({
        message: t('loginRegister.errors.register'),
      });
    },
  });

  const onFinish = (values: ApiUsersSignUpParams) => {
    run(values);
  };

  return (
    <>
      {context}
      <LoginRegisterForm
        title={t('loginRegister.title.register')}
        description={t('loginRegister.description.register')}
        buttonTitle={t('loginRegister.actions.register')}
        onButtonClick={params => onFinish(params as ApiUsersSignUpParams)}
        buttonLoading={loading}
        mode="register"
        bottomLinkTo="login"
      />
    </>
  );
}
