import { ApiManager } from './ApiManager';
import { ConversationDTO, ConversationWithMessagesDTO } from './models/ConversationDTO';

export type ApiDoctorConversationsGetDoctorConversationsListResult = ConversationDTO[];
export type ApiDoctorConversationsGetDoctorConversationMessageListParams = {
  conversationId: string;
};
export type ApiDoctorConversationsGetDoctorConversationMessageListResult = ConversationWithMessagesDTO;
export type ApiDoctorConversationsPostMessageToDoctorConversationParams = {
  conversationId: string;
  messageText: string;
};
export type ApiDoctorConversationsPostMessageToDoctorConversationResult = {
  messageText: string;
};
export type ApiDoctorConversationsGetUnassignedConversationsResult = ConversationDTO[];
export type ApiDoctorConversationsSelfAssignConversationParams = {
  conversationId: string;
};
export type ApiDoctorConversationsSelfAssignConversationResult = void;
export type ApiDoctorConversationsGetOpenChangeRequestConversationsResult = ConversationDTO[];
export type ApiDoctorConversationsGetWaitingForReassignmentConversationsResult = ConversationDTO[];
export type ApiDoctorConversationsRequestOwnerChangeParams = {
  conversationId: string;
  caregiverGroupId: string;
};
export type ApiDoctorConversationsRequestOwnerChangeResult = void;
export type ApiDoctorConversationsGetPatientHistoryResult = ConversationDTO[];

export type StatusChangeRequestType = 'CLOSED' | 'ON_HOLD';

export default class ApiDoctorConversationsManager {
  static async getDoctorConversationsList(): Promise<ApiDoctorConversationsGetDoctorConversationsListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/`);

    return response.data as ApiDoctorConversationsGetDoctorConversationsListResult;
  }

  static async getDoctorConversationMessageList(
    params: ApiDoctorConversationsGetDoctorConversationMessageListParams,
  ): Promise<ApiDoctorConversationsGetDoctorConversationMessageListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/${params.conversationId}/`);

    return response.data as ApiDoctorConversationsGetDoctorConversationMessageListResult;
  }

  static async postMessageToDoctorConversation(
    params: ApiDoctorConversationsPostMessageToDoctorConversationParams,
  ): Promise<ApiDoctorConversationsPostMessageToDoctorConversationResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/doctor-conversations/${params.conversationId}/send/`, {
      message_text: params.messageText,
    });

    return {
      messageText: response.data.message_text,
    };
  }

  static async getUnassignedConversations(): Promise<ApiDoctorConversationsGetUnassignedConversationsResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/unassigned/`);

    return response.data;
  }

  static async selfAssignConversation(
    params: ApiDoctorConversationsSelfAssignConversationParams,
  ): Promise<ApiDoctorConversationsSelfAssignConversationResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(`/api/doctor-conversations/${params.conversationId}/self-assign/`);

    return response.data;
  }

  static async getOpenChangeRequestConversations(): Promise<ApiDoctorConversationsGetOpenChangeRequestConversationsResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/requests/`);

    return response.data;
  }

  static async getWaitingForReassignmentConversations(): Promise<ApiDoctorConversationsGetWaitingForReassignmentConversationsResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/waiting-for-reassignment/`);

    return response.data;
  }

  static async requestOwnerChange(
    params: ApiDoctorConversationsRequestOwnerChangeParams,
  ): Promise<ApiDoctorConversationsRequestOwnerChangeResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(
      `/api/doctor-conversations/${params.conversationId}/request-owner-change/`,
      {
        caregiver_group: params.caregiverGroupId,
      },
    );

    return response.data;
  }

  static async markConversationAsRead(conversationId: string): Promise<void> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(`/api/doctor-conversations/${conversationId}/mark-as-read/`);

    return response.data as void;
  }

  static async requestConversationStatusChange(
    conversationId: string,
    newStatus: StatusChangeRequestType,
  ): Promise<void> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(`/api/doctor-conversations/${conversationId}/update-status/`, {
      new_status: newStatus,
    });

    return response.data as void;
  }

  static async updateConversationSummary(conversationId: string, newSummary: string): Promise<void> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.patch(`/api/doctor-conversations/${conversationId}/update-summary/`, {
      summary: newSummary,
    });

    return response.data as void;
  }

  static async getPatientConversationHistory(
    patientId: string,
  ): Promise<ApiDoctorConversationsGetPatientHistoryResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/doctor-conversations/patient-history/${patientId}/`);

    return response.data;
  }
}
