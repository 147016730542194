import { useInterval } from 'ahooks';
import { Progress } from 'antd';
import Logo from 'components/navigation/Logo';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import PercentUtils from 'utils/PercentUtils';

const NB_OF_INCREMENT = 7;
const LOADING_TIME = 4000;

export type PageLoaderProps = {
  loadingTime?: number;
  text?: string;
};

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});
const inlineStyles = {
  progress: {
    marginTop: 20,
    width: 300,
  },
};

const PageLoader: React.FC<PageLoaderProps> = ({
  loadingTime = LOADING_TIME,
  text = undefined as string | undefined,
}) => {
  const { intervalTime, incrementPercentage } = PercentUtils.getTimePercentage(loadingTime, NB_OF_INCREMENT);

  // State
  const [percent, setPercent] = useState(0);

  // Hooks
  const styles = useStyles();
  useInterval(() => {
    setPercent(percent + incrementPercentage);
  }, intervalTime);

  return (
    <div className={styles.container}>
      <Logo />
      <Progress percent={percent} style={inlineStyles.progress} showInfo={false} />
      {text || undefined}
    </div>
  );
};
export default PageLoader;
