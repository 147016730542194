/* eslint-disable global-require */
import useNotification from 'antd/es/notification/useNotification';
import { AxiosError } from 'axios';
import LoginRegisterForm from 'components/forms/LoginRegisterForm';
import useAuthGuard from 'hooks/useAuthGuard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';

export default function LoginPage() {
  // Hooks
  const { canCallApi, getAccessToken, error, isLoading } = useAuthGuard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [api, context] = useNotification();

  useEffect(() => {
    if (canCallApi) {
      navigate(routes.home.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCallApi]);

  // TODO Refactor - use Auth Guard
  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError;
      const responseObject = axiosError.response?.data as any;
      if (responseObject && responseObject.code === 'token_not_valid') {
        api.error({
          message: t('loginRegister.errors.disconnected'),
        });
      } else if (axiosError.response?.status === 401) {
        api.error({
          message: t('loginRegister.errors.unauthorized'),
        });
      } else {
        api.error({
          message: t('loginRegister.errors.login'),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onFinish = (values: { email: string; password: string }) => {
    getAccessToken({ email: values.email, password: values.password });
  };

  return (
    <>
      {context}
      <LoginRegisterForm
        title={t('loginRegister.title.login')}
        description={t('loginRegister.description.login')}
        buttonTitle={t('loginRegister.actions.login')}
        onButtonClick={params => onFinish(params)}
        buttonLoading={isLoading}
        mode="login"
        bottomLinkTo="register"
      />
    </>
  );
}
