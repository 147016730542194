module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
          Quels sont les symptômes de l'endométriose?
          </h1>

          <div class="date-modified color-secondary">Publié le 4 septembre 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

            <div class="post-body content_box content_box--blog content_box--blog-editorjs">
              <p class="paragraph">
                Ils peuvent être multiples et liés à la localisation de la maladie, chroniques ou périodiques, ou totalement absents
                dans les formes asymptomatiques, et leur intensité n’est pas révélatrice de la gravité des lésions.
              </p>
              <h2>Règles douloureuses et saignements</h2>
              <p class="paragraph">Métrorragie, rectorragie ou spotting ; principalement prémenstruels</p>
              <p class="paragraph">
                Ainsi, les médecins ont coutume de dire que des règles douloureuses pourraient être un symptôme de l’endométriose si
                :
              </p>
              <ul>
                <li>Elle est cyclique et revient avec les règles</li>
                <li>Elle résiste à un simple antalgique type paracétamol</li>
                <li>
                  Elle empêche de mener à bien ses activités quotidiennes (empêche de se lever, de travailler, d’aller en cours).
                </li>
              </ul>
              <p class="paragraph">Si la douleur cède avec un simple paracétamol, il ne faut pas s’alarmer.</p>
              <p class="paragraph">
                Il n’est pas rare&nbsp;<b>quand on souffre d’endométriose d’avoir des règles abondantes</b>, avec des caillots et
                qui&nbsp;<b>durent plus de 7 jours</b>&nbsp;notamment quand il existe une adénomyoseisolée ou associée à
                l’endométriose.
              </p>
              <p class="paragraph">
                Les saignements en dehors de la période de règles, les métrorragies, peuvent être également un symptôme
                d’adénomyose.
              </p>
              <p class="paragraph">
                Toutefois, ils peuvent être le signe de plusieurs autres maladies qu’un gynécologue pourra identifier après quelques
                examens (polypes, fibromes, ….).
              </p>
              <p class="paragraph">
                Les rectorragies, saignements issus du rectum, peuvent évoquer une endométriose digestive surtout si cela survient
                pendant les régles. Comme pour les métrorragies, il peut tout autant s’agir d’autre chose (hémorroïdes internes par
                exemple).
              </p>
              <p class="paragraph">
                Quant au spotting, il s’agit de traces de sang, souvent moins abondantes que les règles ou les métrorragies (dans de
                rares cas, les spottings peuvent être abondants avec caillots), qui arrivent en dehors des règles. Souvent, cela est
                le signe d’une modification de la muqueuse utérine. ils sont assez fréquents en cas de contraception en continu.
                Plusieurs causes à cela :
              </p>
              <ul>
                <li>
                  Un changement de pilule, il faut laisser le corps “s’adapter” à cette nouvelle pilule, notamment lors des 2
                  premiers mois.
                </li>
                <li>Une contraception dont le dosage n’est plus adapté</li>
                <li>
                  Une atrophie de la muqueuse utérine (ce qui arrive souvent au bout de plusieurs mois quand la pilule est prise en
                  continu) et qui nécessite un ajustement (à définir avec le médecin).
                </li>
              </ul>
              <p class="paragraph">
                Cette douleur de règles (dysménorrhée) associée à d’autres symptômes (troubles digestifs et/ou urinaires, douleurs
                pendant les rapports sexuels…) peut être évocatrice d’endométriose.
              </p>
              <p class="paragraph">
                Dans tous les cas,&nbsp;<b>quand la douleur devient invalidante, il faut en parler à un médecin</b>.
              </p>
              <h2>Infertilité</h2>
              <p class="paragraph">La maladie est souvent révélée à l’occasion d’un bilan de fertilité</p>
              <p class="paragraph">
                <b>L’endométriose est souvent diagnostiquée lors d’un bilan d’infertilité</b>. On associe souvent cette maladie à
                l’infertilité or toutes les femmes atteintes d’endométriose ne sont pas concernées ; 30 à 40% doivent faire face à
                un problème d’infertilité.
              </p>
              <h2>Troubles digestifs</h2>
              <p class="paragraph">
                On parle <b>d’endométriose digestive</b>&nbsp;quand l’endométriose se développe sur les intestins, le colon, le
                rectum.
              </p>
              <p class="paragraph">Les troubles digestifs associés à l’endométriose sont notamment :</p>
              <ul>
                <li>Une alternance de diarrhée / constipation, symptôme aggravé au moment des règles</li>
                <li>Des douleurs lors de la défécation</li>
                <li>Plus rarement des rectorragies (présence de sang dans les selles)</li>
                <li>Des phénomènes d’occlusion (ballonnements abdominaux, arrêt des selles et des gaz)</li>
              </ul>
              <p class="paragraph">
                Ces symptômes sont relativement typiques pour une&nbsp;<b>endométriose digestive</b>, néanmoins dans de nombreux cas
                ils sont seulement&nbsp;le
                <b>résultat de l’inflammation qui accompagne une endométriose superficielle localisée à proximité du rectum</b>.
                Enfin, de nombreuses patientes présentant une endométriose (jusqu’à 50-60% selon certains auteurs) ont des symptômes
                digestifs dus à l’existence concomitante d’une maladie digestive fonctionnelle (comme le côlon irritable) ou
                inflammatoire (maladie de Crohn, Rectocolite hémorragique), dont les symptômes sont similaires à ceux de
                l’endométriose. Le diagnostic est donc complexe.
              </p>
              <h2>Troubles urinaires</h2>
              <p class="paragraph">Brûlure urinaire, sang dans les urines, mictions fréquentes.</p>
              <p class="paragraph">
                On parle <b>d’endométriose vésicale</b>&nbsp;quand l’endométriose se développe au niveau de la vessie.
              </p>
              <p class="paragraph">
                Il s’agit généralement de nodules (lésions fibreuses) ayant tendance à infiltrer la vessie. Elles entraînent :
              </p>
              <ul>
                <li>Des douleurs pelviennes et urinaires</li>
                <li>Des dysuries (difficultés pour vider la vessie)</li>
                <li>Une pollakiurie (envie fréquente d’uriner)</li>
                <li>La présence parfois de sang dans les urines</li>
              </ul>
              <p class="paragraph">
                Les femmes parlent souvent de&nbsp;<b>symptômes rappelant l’infection urinaire</b>&nbsp;mais avec un examen
                cytobactériologique des urines (ECBU) négatif.
              </p>
              <h2>Fatigue Chronique</h2>
              <p class="paragraph">Impactant la qualité de vie.</p>
              <p class="paragraph">Bien souvent,&nbsp;<b>les femmes atteintes d’endométriose souffrent de fatigue chronique</b>.</p>
              <p class="paragraph">
                On pourrait expliquer cela simplement&nbsp;: pour gérer une douleur forte, régulière ou constante, invalidante, la
                femme puise dans ses «&nbsp;réserves&nbsp;». Les réveils nocturnes dus aux douleurs ou aux envies fréquentes
                d’uriner ne facilitent pas la récupération. Dans la mesure où l’endométriose provoque des symptômes chroniques, il
                n’y a pas de temps de récupération pour l’organisme, fatigué par la valse des hormones (traitements, ménopause
                artificielles), les effets secondaires de certains médicaments, la convalescence des chirurgies.
              </p>
              <p class="paragraph"><b>Tant que la douleur est là, forte et non gérée, la fatigue s’installe inévitablement.</b></p>
              <h2>Douleurs pelviennes et lombaires</h2>
              <p class="paragraph">
                Douleurs situées dans le petit bassin, du nombril au bas ventre. Lombalgie, sciatique ou cruralgie (douleurs
                irradiant dans la jambe)
              </p>
              <ul>
                <li>
                  Outre la douleur générée par l’inflammation due aux micros hémorragies lors du cycle, la perte de mobilité des
                  organes est source de douleur. En effet,&nbsp;<b>la particularité de l’endométriose est de créer des adhérences</b
                  >&nbsp;qui vont entrainer une rétractation des tissus. De plus, le petit bassin est une zone très innervée. Dès
                  lors on comprend qu’une lésion d’endométriose, si petite soit-elle, va générer de fortes douleurs.
                </li>
                <li>
                  Se développe alors une mémoire de la douleur au niveau des circuits de transmission (des nerfs vers le cerveau).
                  Si la douleur n’est pas dans la tête et qu’elle part bien du corps, le cerveau va néanmoins développer une
                  hypersensibilité qui augmentera l’information douloureuse et la rendra toujours présente et parfois même quand
                  toutes les lésions sont supprimées par la chirurgie.
                </li>
              </ul>
              <h2>Dyspareunie</h2>
              <p class="paragraph">Douleur ressentie lors des rapports sexuels</p>
              <p class="paragraph">
                Dans le cadre d’une endométriose, on parle souvent de dyspareunie profonde (douleur ressentie au niveau du bas
                ventre, lors de rapports sexuels avec pénétration, suite au contact entre la verge et le fond du vagin). Les
                dyspareunies témoignent de lésions situées près du vagin et au niveau de la cloison recto-vaginale, mais elles
                peuvent être également le résultat de l’inflammation qui accompagne une endométriose superficielle localisée en
                regard du fond vaginal, ou celui d’une adénomyose&nbsp;sévère. Ce symptôme est fréquemment rencontré dans
                l’endométriose.
              </p>
              <p class="paragraph">
                La&nbsp;<b>dyspareunie constitue un des symptômes permettant le diagnostic de l’endométriose</b>. C’est pourquoi il
                est important d’en parler au médecin en consultation.
              </p>
              <p class="paragraph">
                Souvent, parce qu’elle craint d’avoir mal, la femme se contracte inconsciemment, rendant impossible toute
                pénétration. Un médecin peu attentif pourrait diagnostiquer un «&nbsp;vaginisme&nbsp;» (contraction musculaire
                prolongée ou récurrente des muscles du plancher pelvien qui entourent l’ouverture du vagin) et qualifier cette
                douleur de «&nbsp;psychologique&nbsp;»… Sans chercher à comprendre que la douleur dite «&nbsp;balistique&nbsp;»
                vient du fait que dans certaines positions, la verge appuie sur un nodule d’endométriose profonde.
              </p>
              <p class="paragraph">
                Pour ne pas abîmer sa relation de couple, il est important d’en parler également à son conjoint afin de trouver des
                solutions pour préserver sa vie intime. Des sexologues peuvent être de bons conseils.
              </p>
            </div>



        </div>
      </section>
    </div>
  </body>
</html>
`;
