module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
          Le TENS contre les douleurs ?
          </h1>

          <div class="date-modified color-secondary">Publié le 10 octobre 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph"><b>C’est quoi un TENS ?</b></p>
            <p class="paragraph">
              En anglais, TENS signifie Transcutaneous Electrical Nerve Stimulator et, en français, stimulateur électrique
              transcutané.
            </p>
            <p class="paragraph">
              Il s’agit d’un moyen de lutte utile contre la douleur permettant de poursuivre ses activités habituelles. Le TENS
              est un petit appareil que l’on peut accrocher à la ceinture ou glisser dans une poche, et donc emporter partout avec
              soi. Il est facile à utiliser.
            </p>
            <p class="paragraph">
              L’utilisation du TENS se fait sur prescription d’un médecin. Cet appareil peut être loué ou acheté après un essai
              positif auprès d’un thérapeute. Renseignez-vous auprès de votre assurance maladie au début du traitement pour
              connaître les modalités de remboursement.
            </p>
            <p class="paragraph"><b>Les bénéfices</b></p>
            <p class="paragraph">
              C’est une méthode sûre et efficace qui entraîne une réduction de certaines douleurs (notamment d’irritation des
              nerfs, rhumatologique, post-traumatique, etc.).
            </p>
            <p class="paragraph"><b>Comment ça marche ?</b></p>
            <p class="paragraph">
              Par l’intermédiaire d’une ou deux paires d’électrodes collées sur la peau, le TENS délivre des impulsions
              électriques indolores et de faible intensité sur une zone douloureuse ou sur le trajet d’un nerf. Il est alimenté
              par une batterie. Ces impulsions électriques activent des fibres nerveuses rapides, de plus gros calibres que celles
              utilisées pour véhiculer la douleur. Le premier arrivé est le premier servi! Le message perçu par le cerveau est
              celui des fourmillements délivré par le TENS masquant ainsi la douleur.
            </p>
            <p class="paragraph"><b>Y a-t-il des risques ?</b></p>
            <p class="paragraph">
              Le TENS fonctionne avec une batterie de faible voltage: par conséquent vous n’encourez aucun risque. Cependant, il
              est important d’informer votre médecin avant utilisation:
            </p>
            <ul>
              <li>
                si vous êtes porteur d’un stimulateur, d’un défibrillateur cardiaque ou d’un autre implant médical actif car le
                port du TENS peut créer des interférences
              </li>
              <li>
                si vous avez des risques d’allergies cutanées, les électrodes autocollantes pouvant provoquer une réaction au
                niveau de la peau
              </li>
              <li>si vous êtes enceinte</li>
            </ul>
            <p class="paragraph">
              Le TENS diminue l’attention. Il est fortement déconseillé d’employer le TENS lorsque vous conduisez un véhicule .
            </p>
            <p class="paragraph">Les électrodes ne doivent pas être placées sur le cou.</p>
            <p class="paragraph">
              Vous pouvez utiliser l’appareil dans votre vie quotidienne, faire vos courses par exemple. S’il est en marche, il
              peut déclencher les alarmes à l’entrée et à la sortie des magasins. Pensez donc à l’éteindre quelques minutes.
            </p>
            <p class="paragraph"><b>Conseils d’utilisation</b></p>
            <p class="paragraph">Voici un rappel des informations qu’un soignant vous aura préalablement fournies.</p>
            <p class="paragraph">Le TENS doit toujours être éteint avant de faire les branchements.</p>
            <p class="paragraph">
              Le TENS comprend deux canaux distincts, dont il est possible de régler l’intensité séparément. Branchez un ou les
              deux câbles sur les canaux, puis reliez-les aux électrodes autocollantes.
            </p>
            <p class="paragraph">
              Retirez délicatement les électrodes de leur support et appliquez-les sur la peau propre et sèche.
            </p>
            <p class="paragraph">
              Si les électrodes se décollent lors de leur utilisation, la colle a peut-être séché, humectez-les très légèrement
              avec un peu d’eau. Si elles sont trop humides, laissez-les sécher à l’air libre. Si elles ne collent plus et sont
              trop usagées, elles ne répartiront pas de façon homogène la conduction électrique. Prenez-en des neuves.
            </p>
            <p class="paragraph">
              Cette stimulation ne doit en aucun cas déclencher des douleurs ou des contractions musculaires.
            </p>
            <p class="paragraph">
              Pour régler le TENS, augmentez l’intensité jusqu’au moment où vous ressentez le début de la sensation douloureuse,
              puis redescendez d’un ou deux crans. L’intensité se règle en fonction de votre propre sensibilité.
            </p>
            <p class="paragraph">
              Si la douleur persiste, éteignez l’appareil et changez le positionnement des électrodes, puis ré-augmentez
              progressivement l’intensité. Il vous sera peut-être nécessaire de déplacer plusieurs fois les électrodes pour
              chercher l’endroit qui vous convient le mieux dans la zone prédéterminée.
            </p>
            <p class="paragraph">
              Il est conseillé d’utiliser le TENS entre 30 minutes à 1 heure environ et jusqu’à 5 à 6 fois par jour.
            </p>
            <p class="paragraph">
              Si les électrodes sont mal connectées, vous ne pourrez pas augmenter l’intensité, celle-ci reviendra automatiquement
              à zéro. Vérifiez tous les branchements et connexions.
            </p>
            <p class="paragraph">
              Si vous recollez les électrodes sur le support en fin d’utilisation, elles peuvent être réutilisées de nombreuses
              fois (plus ou moins selon le type de peau et de pilosité). Vous prolongerez leur utilisation si vous les placez au
              frais dans le bas de votre réfrigérateur
            </p>
            <p class="paragraph">Les fils sont fragiles, évitez de tirer directement dessus.</p>
          </div>


      </section>
    </div>
  </body>
</html>
`;
