module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Comment fonctionne l'ALD 31?
          </h1>

          <div class="date-modified color-secondary">Publié le 10 juin 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
              Pour les formes d'endométriose&nbsp;impactant la qualité de vie et nécessitant, au cours de leur évolution, des
              examens et des soins nombreux, un accès à la prise en charge à 100&nbsp;% au titre de l'<a
                target="_blank"
                href="https://www.ameli.fr/paris/assure/droits-demarches/maladie-accident-hospitalisation/affection-longue-duree-ald/affection-longue-duree-maladie-chronique"
                >ALD&nbsp;31</a
              >&nbsp;(affection hors liste des 30 ALD) est possible si les critères d'admission sont remplis.
            </p>
            <h3>Critères d'admission de l'endométriose en ALD 31</h3>
            <p class="paragraph">
              Une ALD dite «&nbsp;hors liste&nbsp;» ou ALD 31 est une maladie grave qui n'est pas dans la liste des 30 ALD, qui
              évolue sur une durée prévisible supérieure à 6 mois et dont le traitement est particulièrement coûteux.
            </p>
            <p class="paragraph">
              Pour que les examens et les soins soient acceptés en ALD hors liste 31, la prise en charge de
              l'endométriose&nbsp;doit comprendre obligatoirement un traitement médicamenteux.
            </p>
            <p class="paragraph">
              2 critères parmi les 4 suivants sont également obligatoires et doivent donc être présents&nbsp;:
            </p>
            <ul>
              <li>une hospitalisation à venir&nbsp;;</li>
              <li>des actes techniques médicaux répétés&nbsp;;</li>
              <li>des actes biologiques répétés&nbsp;;</li>
              <li>des soins paramédicaux fréquents et réguliers.</li>
            </ul>
            <p class="paragraph"><b>Recommandations du directeur médical de Gynger</b></p>
            <p class="paragraph">
              “L’obtention de l’ALD 31 reste toujours très compliquée et malheureusement très peu de femmes se voient être
              acceptées. La demande se réalise avec son médecin traitant ou son gynécologue. Soyez vraiment confiante en votre
              professionnel de santé avec qui vous allez constituer le dossier, soyez sure qu’il est bienveillant et qu’il ne vous
              “lachera” pas, c’est vraiment lui qui pourra défendre votre dossier ! N’hésitez pas à demander conseils à votre
              sage-femme référente sur Gynger si besoin de plus d’informations à ce sujet”
            </p>
            <p class="paragraph"><b>Quentin MARQUET, pharmacien et directeur médical de Gynger</b></p>
          </div>


        </div>
      </section>
    </div>
  </body>
</html>
`;
