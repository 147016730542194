export type Department = {
  name: string;
  number: string;
};

export const departments = [
  { number: '01', name: 'Ain' },
  { number: '02', name: 'Aisne' },
  { number: '03', name: 'Allier' },
  { number: '04', name: 'Alpes-de-Haute-Provence' },
  { number: '05', name: 'Hautes-Alpes' },
  { number: '06', name: 'Alpes-Maritimes' },
  { number: '07', name: 'Ardèche' },
  { number: '08', name: 'Ardennes' },
  { number: '09', name: 'Ariège' },
  { number: '10', name: 'Aube' },
  { number: '11', name: 'Aude' },
  { number: '12', name: 'Aveyron' },
  { number: '13', name: 'Bouches-du-Rhône' },
  { number: '14', name: 'Calvados' },
  { number: '15', name: 'Cantal' },
  { number: '16', name: 'Charente' },
  { number: '17', name: 'Charente-Maritime' },
  { number: '18', name: 'Cher' },
  { number: '19', name: 'Corrèze' },
  { number: '21', name: "Côte-d'Or" },
  { number: '22', name: "Côtes-d'Armor" },
  { number: '23', name: 'Creuse' },
  { number: '24', name: 'Dordogne' },
  { number: '25', name: 'Doubs' },
  { number: '26', name: 'Drôme' },
  { number: '27', name: 'Eure' },
  { number: '28', name: 'Eure-et-Loir' },
  { number: '29', name: 'Finistère' },
  { number: '2A', name: 'Corse-du-Sud' },
  { number: '2B', name: 'Haute-Corse' },
  { number: '30', name: 'Gard' },
  { number: '31', name: 'Haute-Garonne' },
  { number: '32', name: 'Gers' },
  { number: '33', name: 'Gironde' },
  { number: '34', name: 'Hérault' },
  { number: '35', name: 'Ille-et-Vilaine' },
  { number: '36', name: 'Indre' },
  { number: '37', name: 'Indre-et-Loire' },
  { number: '38', name: 'Isère' },
  { number: '39', name: 'Jura' },
  { number: '40', name: 'Landes' },
  { number: '41', name: 'Loir-et-Cher' },
  { number: '42', name: 'Loire' },
  { number: '43', name: 'Haute-Loire' },
  { number: '44', name: 'Loire-Atlantique' },
  { number: '45', name: 'Loiret' },
  { number: '46', name: 'Lot' },
  { number: '47', name: 'Lot-et-Garonne' },
  { number: '48', name: 'Lozère' },
  { number: '49', name: 'Maine-et-Loire' },
  { number: '50', name: 'Manche' },
  { number: '51', name: 'Marne' },
  { number: '52', name: 'Haute-Marne' },
  { number: '53', name: 'Mayenne' },
  { number: '54', name: 'Meurthe-et-Moselle' },
  { number: '55', name: 'Meuse' },
  { number: '56', name: 'Morbihan' },
  { number: '57', name: 'Moselle' },
  { number: '58', name: 'Nièvre' },
  { number: '59', name: 'Nord' },
  { number: '60', name: 'Oise' },
  { number: '61', name: 'Orne' },
  { number: '62', name: 'Pas-de-Calais' },
  { number: '63', name: 'Puy-de-Dôme' },
  { number: '64', name: 'Pyrénées-Atlantiques' },
  { number: '65', name: 'Hautes-Pyrénées' },
  { number: '66', name: 'Pyrénées-Orientales' },
  { number: '67', name: 'Bas-Rhin' },
  { number: '68', name: 'Haut-Rhin' },
  { number: '69', name: 'Rhône' },
  { number: '70', name: 'Haute-Saône' },
  { number: '71', name: 'Saône-et-Loire' },
  { number: '72', name: 'Sarthe' },
  { number: '73', name: 'Savoie' },
  { number: '74', name: 'Haute-Savoie' },
  { number: '75', name: 'Paris' },
  { number: '76', name: 'Seine-Maritime' },
  { number: '77', name: 'Seine-et-Marne' },
  { number: '78', name: 'Yvelines' },
  { number: '79', name: 'Deux-Sèvres' },
  { number: '80', name: 'Somme' },
  { number: '81', name: 'Tarn' },
  { number: '82', name: 'Tarn-et-Garonne' },
  { number: '83', name: 'Var' },
  { number: '84', name: 'Vaucluse' },
  { number: '85', name: 'Vendée' },
  { number: '86', name: 'Vienne' },
  { number: '87', name: 'Haute-Vienne' },
  { number: '88', name: 'Vosges' },
  { number: '89', name: 'Yonne' },
  { number: '90', name: 'Territoire de Belfort' },
  { number: '91', name: 'Essonne' },
  { number: '92', name: 'Hauts-de-Seine' },
  { number: '93', name: 'Seine-Saint-Denis' },
  { number: '94', name: 'Val-de-Marne' },
  { number: '95', name: "Val-d'Oise" },
  { number: '971', name: 'Guadeloupe' },
  { number: '972', name: 'Martinique' },
  { number: '973', name: 'Guyane' },
  { number: '974', name: 'La Réunion' },
  { number: '976', name: 'Mayotte' },
];
