/* eslint-disable global-require */
import { Collapse, Image, Typography } from 'antd';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

const PAGE_CONTENT_PADDING = 8;
const PAGE_CONTENT_VERTICAL_PADDING_TOTAL = 2 * PAGE_CONTENT_PADDING;
const RATIO_16_9 = 16 / 9;

const panelStyle: React.CSSProperties = {
  marginBottom: 24,
  background: AppColors.backgroundGrey,
  border: 'none',
  borderRadius: 4,
};

const data = [
  {
    key: '1',
    label: 'Quelles questions puis-je poser sur Gynger ?',
    children: (
      <div>
        <p>
          🤔 “Qui dois-je consulter pour… ?” “Est-ce que c’est normal que je … ?” “Qu’est-ce ça veut dire … ?” “Que
          faire si je me sens… ?”…
        </p>
        <p>Pose ta question sur Gynger, un soignant te répondra rapidement !</p>
        <p>
          🧑‍⚕️ Gynger est la messagerie gynécologique pour toutes! Derrière celle-ci, nous avons mobilisé des
          professionnels de santé et des thérapeutes avec des expertises complémentaires et variées. Notre objectif est
          qu’avec Gynger tu sois informée, accompagnée et orientée selon tes besoins.
        </p>
        <p>
          🙌 Tu peux donc poser toutes tes questions de santé féminine qui te préoccupent, d’une simple recherche
          d’information à l’attente d’un avis médical, nous sommes à ton écoute.
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: "Est-ce que mon employeur sait que j'utilise Gynger ?",
    children: (
      <div>
        <p>
          Non 🌞 Ton employeur/ ta mutuelle ne sait pas que tu utilises Gynger et n’a aucun accès à tes données sur
          l’application. Les conversations comme tes données présentes sur l’applications sont strictement
          confidentielles.
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: 'Qui a accès aux conversations que je mène sur Gynger ?',
    children: (
      <div>
        <p>
          Tes conversations sont accessibles uniquement par toi et par l’équipe de soignants avec qui tu communiques.
          Chaque membre de notre équipe de soins est tenu au secret médical ou secret professionnel, tes conversations
          sont donc strictement confidentielles! ✨
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'Puis-je utiliser Gynger alors que je n’ai aucune pathologie ou que je ne suis pas une femme ?',
    children: (
      <div>
        <p>
          Bien sûr 🌻 Gynger s’adresse à tout.e.s celles et ceux qui souhaitent être informé.e.s, accompagné.e.s et
          orienté.e.s dans leur parcours de soins ou celui de leur proche. Nos soignants sont spécialisés en santé
          féminine et apporteront des réponses bienveillantes à tout.e.s !
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Puis-je avoir confiance dans les experts de Gynger ?',
    children: (
      <div>
        <ul style={{ paddingInlineStart: '12px' }}>
          <li>
            Gynger a pris soin de sélectionner des professionnels de santé et des thérapeutes formés spécifiquement aux
            problématiques de santé féminine que tu peux rencontrer,
          </li>
        </ul>
        <p />
        <ul style={{ paddingInlineStart: '12px' }}>
          <li>
            Chacun d’eux s’est engagé à respecter notre charte de bienveillance et sera à ton écoute pour que tu te
            sentes au mieux,
          </li>
        </ul>
        <p />
        <ul style={{ paddingInlineStart: '12px' }}>
          <li>La messagerie Gynger a un taux de satisfaction supérieur à 95% auprès des premières utilisatrices!</li>
        </ul>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '9',
    label: 'Est-ce que Gynger remplace une consultation médicale ?',
    children: (
      <div>
        <p>
          L’accompagnement par chat proposé par Gynger est complémentaire à la consultation physique, et ne vient en
          aucun cas la remplacer. En cas d’urgence, appelez le 15 ! ☎️
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '10',
    label: 'Est-ce que Gynger est payant ?',
    children: (
      <div>
        <p>Gynger est un service offert ton entreprise/ ta mutuelle!</p>
        <p />
        <p>
          Ainsi, si tu ne payes pas pour avoir un accès illimité à notre messagerie gynécologique, c’est grâce à
          l’engagement de ton entreprise/ ta mutuelle 🤝
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: '11',
    label: 'Est-ce que mes données personnelles sont en sécurité ?',
    children: (
      <div>
        <ul style={{ paddingInlineStart: '12px' }}>
          <li>
            L’application Gynger et tes données personnelles sont hébergées sur un serveur agréé Hébergeur de Données de
            Santé par le Ministère de la Santé et nous respectons minutieusement la loi sur le Règlement Général de
            Protection des Données.
          </li>
        </ul>
        <ul style={{ paddingInlineStart: '12px' }}>
          <li>
            Aussi, grâce à l’expertise de son équipe technique, Gynger a pris soin de mettre en place des procédures qui
            garantissent la sécurité de tes données de bout en bout.
          </li>
        </ul>
      </div>
    ),
    style: panelStyle,
  },
];

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  container: {
    padding: 8,
    height: '100%',
    overflowY: 'scroll',
  },
  title: {
    color: `${AppColors.main} !important`,
  },
  video: {
    marginTop: 20,
    border: 'none',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '20px',
  },
});

export default function FaqPage() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const { isSmallScreen, width } = useIsSmallScreen();

  const videoWidth = isSmallScreen ? width - PAGE_CONTENT_VERTICAL_PADDING_TOTAL : width / 2;
  const videoHeight = videoWidth / RATIO_16_9;

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('appName')} />

      <div className={styles.container}>
        <Text>{t('faq.subtitle')}</Text>
        <br />

        <center>
          <iframe
            width={videoWidth}
            height={videoHeight}
            src={t('faq.videoUrl')}
            title="Gynger"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className={styles.video}
          />
        </center>

        <Title level={4} className={styles.title}>
          {t('faq.networks')}
        </Title>

        <div className={styles.iconContainer}>
          <Link to={t('social.instagram')} target="_blank" rel="noopener noreferrer">
            <Image
              alt="social-instagram"
              height={50}
              width={50}
              preview={false}
              src={require('../assets/social-instagram.png')}
            />
          </Link>
          <Link to={t('social.linkedIn')} target="_blank" rel="noopener noreferrer">
            <Image
              alt="social-linkedIn"
              height={50}
              width={50}
              preview={false}
              src={require('../assets/social-linkedin.png')}
            />
          </Link>
        </div>

        <Title level={4} className={styles.title}>
          {t('faq.questions')}
        </Title>

        <Collapse bordered={false} items={data} ghost />
      </div>
    </div>
  );
}
