const supportEmail = 'support@gyngercare.com';
const codeRequestEmail = 'code-request@gyngercare.com';
const appUrl = process.env.PUBLIC_URL || 'https://chat.gyngercare.com';

const fr = {
  appName: 'Gynger',
  appTagline: "L'app de suivi de santé des femmes",
  legal: {
    website: 'https://gyngercare.com/',
    termsAndPrivacy: 'https://gyngercare.com/mentionslegales/',
  },
  social: {
    instagram: 'https://www.instagram.com/gynger.care/',
    linkedIn: 'https://www.linkedin.com/company/gynger-care/',
  },
  global: {
    user: {
      me: 'Moi',
    },
    support: {
      supportEmail,
      supportEmailWithSubject: `${supportEmail}?subject=Demande de support`,
      codeRequestEmailWithSubject: `${codeRequestEmail}?subject=Demande de code&body=Bonjour, j'aimerais créer un compte sur Gynger mais mon entreprise n'est pas partenaire. Pouvez-vous m'aider à obtenir un code ?`,
    },
    errors: {
      authentication: {
        title: 'Non autorisé',
        message: `Une erreur s'est produite lors de votre connexion. Veuillez recharger la page (${appUrl}) ou contacter le support à l'adresse ${supportEmail}, si le problème persiste.`,
      },
      '403': {
        title: 'Non autorisé',
        message: `Désolé, vous n'êtes pas autorisé à accéder à cette page. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
      '404': {
        title: 'Page non trouvée',
        message: `Désolé, la page que vous avez visitée n'existe pas. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
      unknown: 'Inconnu',
    },
    buttons: {
      cancel: 'Annuler',
      ok: 'OK',
      reset: 'Réinitialiser',
      update: 'Modifier',
      close: 'Fermer',
    },
    filters: {
      search: 'Filtrer',
      clear: 'Effacer les filtres',
    },
  },
  caregiverGroups: {
    'Dieteticiens-Nutritionnistes': 'Diététiciens-Nutritionnistes',
    Radiologues: 'Radiologues',
    Ostheopates: 'Ostéopathes',
    Gynecologues: 'Gynécologues',
    'Gynecologues-obstetriciens': 'Gynécologues obstétriciens',
    Urologues: 'Urologues',
    Kinesitherapeutes: 'Kinésithérapeutes',
    Algologues: 'Algologues',
    Naturopathes: 'Naturopathes',
    Sophrologues: 'Sophrologues',
    'Sages-femmes': 'Sages-femmes',
    Psychologues: 'Psychologues',
    'Gynger-feedback': 'Feedback Gynger',
    Sexologues: 'Sexologues',
    'Medecins-Generalistes': 'Médecins généralistes',
    'Internes-gynecologie': 'Internes en gynécologie',
  },
  menu: {
    home: 'Messages',
    conversation: 'Conversations',
    newChat: 'Nouvelle question',
    settings: 'Mon Profil',
    notFound: 'Page non trouvée',
    unauthorized: 'Non autorisé',
    login: 'Connexion',
    register: 'Création de compte',
    passwordReset: 'Réinitialisation du mot de passe',
    logout: 'Déconnexion',
    onboarding: 'Découvrir Gynger',
    moreInfo: "Plus d'informations",
    support: 'Support',
    info: 'Articles',
    caregiversDirectory: 'Experts',
    caregiverProfile: 'Profil',
  },
  home: {
    title: 'Accueil',
  },
  loginRegister: {
    title: {
      login: 'Connexion à Gynger',
      register: 'Créez un compte Gynger',
    },
    description: {
      login: 'Bienvenue sur Gynger ! Renseignez vos informations de connexion pour vous authentifier.',
      register: 'Créez un compte avec votre mot de passe, définissez un mot de passe et renseignez le code.',
      registerAskIfCode: "Avez-vous un code d'accès à Gynger?",
      registerNoCode:
        "L'accès à Gynger requiert un code fourni par votre employeur ou votre mutuelle.\n\nContactez-nous pour qu'ils deviennent partenaire de Gynger!",
    },
    linkToSite: {
      tagline: 'Gynger, la messagerie gynécologique pour toutes.',
      link: "Cliquez ici pour plus d'informations.",
    },
    fields: {
      email: {
        title: 'E-mail',
        placeholder: 'E-mail',
        errors: {
          notEmail: "Format d'email invalide",
        },
      },
      password: {
        title: 'Mot de passe',
        placeholder: 'Mot de passe',
      },
      passwordConfirmation: {
        title: 'Confirmation du mot de passe',
        placeholder: 'Confirmation du mot de passe',
        errors: {
          required: 'Confirmez votre mot de passe',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
      firstName: {
        title: 'Prénom',
        placeholder: 'Prénom',
        errors: {
          required: 'Le prénom est requis',
        },
      },
      lastName: {
        title: 'Nom',
        placeholder: 'Nom de famille',
        errors: {
          required: 'Votre nom de famille est requis',
        },
      },
      licenseCode: {
        title: 'License',
        placeholder: 'Code de la license fourni par votre partenaire ou Gynger',
        errors: {
          required: 'Le code de la license est requis',
        },
      },
      tc_and_privacy_policy_consent: {
        title: "J'ai lu et j'accepte les CGU et la politique de confidentialité",
        errors: {
          required: 'Vous devez accepter',
        },
      },
      personal_information_access_consent: {
        title:
          "Je consens à ce que les informations que je transmets sur la messagerie peuvent être lues par l'ensemble des soignants de Gynger Care",
        errors: {
          required: 'Vous devez accepter',
        },
      },
      email_notifications_consent: {
        title: "J'accepte de recevoir des notifications par email de la part de Gynger Care",
      },
    },
    actions: {
      login: 'Connexion',
      register: 'Création de compte',
      alreadyRegistered: 'Déjà un compte ? Authentifiez-vous avec votre email',
      noAccount: 'Pas de compte ? Créez un compte avec votre email',
      passwordReset: 'Mot de passe oublié ?',
      iHaveACode: "J'ai un code d'accès",
      iDontHaveACode: "Je n'ai pas de code d'accès",
      askForCode: 'Demander un code',
    },
    errors: {
      login: `Une erreur s'est produite lors de la connexion. Merci de contacter ${supportEmail}`,
      register: `Une erreur s'est produite lors de la création de votre compte. Merci de contacter ${supportEmail}`,
      unauthorized: `E-mail ou mot de passe non reconnu. Si vous rencontrez des problèmes, contactez ${supportEmail}`,
      disconnected: `Vous avez été déconnecté(e). Veuillez vous reconnecter.`,
    },
  },
  passwordReset: {
    title: 'Réinitialisation du mot de passe',
    description: {
      sendEmail:
        'Remplissez ce formulaire avec votre email. Un lien vous sera envoyé pour réinitialiser votre mot de passe',
      reset: 'Utilisez ce formulaire pour réinitialiser votre mot de passe. Définissez un nouveau mot de passe',
    },
    fields: {
      email: {
        title: 'E-mail',
        placeholder: 'E-mail',
        errors: {
          notEmail: "Format d'email invalide",
        },
      },
      password: {
        title: 'Mot de passe',
        placeholder: 'Mot de passe',
        errors: {
          required: 'Définissez un nouveau mot de passe',
          minLength: 'Votre mot de passe doit contenir au moins 8 caractères',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
      passwordConfirmation: {
        title: 'Confirmation du mot de passe',
        placeholder: 'Confirmation du mot de passe',
        errors: {
          required: 'Confirmez votre nouveau mot de passe',
          minLength: 'Votre mot de passe doit contenir au moins 8 caractères',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
    },
    actions: {
      sendEmail: 'Envoyer le lien',
      reset: 'Réinitialiser',
    },
    success: {
      sendEmail:
        'Un email vous a été envoyé. Cliquez sur le lien que vous avez reçu pour réinitialiser votre mot de passe',
      reset: 'Votre mot de passe a été réinitialisé',
    },
    errors: {
      sendEmail: `Une erreur s'est produite lors de l'envoi du lien. Merci de contacter ${supportEmail}`,
      reset: `Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Merci de contacter ${supportEmail}`,
    },
  },
  caregivers: {
    list: {
      errors: {
        fetch: "Une erreur s'est produite lors du chargement de la liste des spécialistes",
      },
    },
    actions: {
      requestOwnerChange: {
        success: 'La conversation a été transférée à un spécialiste',
        error: "Une erreur s'est produite lors du transfert de la conversation à un spécialiste",
      },
      statusChange: {
        success: 'Le statut de la conversation a été mis à jour.',
        error: "Une erreur s'est produite lors du changement de statut de la conversation",
      },
      selfAssign: {
        success: 'Vous vous êtes assigné la conversation!',
        error: "Une erreur s'est produite lors du transfert de la conversation vers vous-même",
      },
    },
  },
  chats: {
    title: 'Mes conversations',
    subtitle: {
      unassigned: 'Demandes de groupe',
      myRequest: 'Mes demandes',
      waitingForReassignment: 'En attente',
      assigned: 'Mes conversations',
      closed: 'Fermées',
      onHold: 'En pause',
      history: 'Historique',
      summary: 'Notes',
    },
    caregivers: {
      first: 'Marion, Sage-femme',
      second: 'Aurore, Sage-femme',
      third: 'Laurine, Sage-femme',
    },
    actions: {
      transferTo: 'Orienter vers',
      summary: 'Notes',
      history: 'Historique',
      selfAssign: 'Prendre ce patient en charge',
      initiateConversation: 'Je parle à un soignant',
      button: 'Actions',
      putOnHold: 'Mettre en pause',
      close: 'Fermer',
    },
    fields: {
      message: {
        placeholder: 'Tapez votre message ...',
      },
    },
    noCaregiver: {
      avatar: 'NA',
      name: {
        forPatient: 'Équipe Gynger',
        forCaregiver: 'Équipe Gynger',
      },
      extraConversationMessage: {
        forPatient:
          "Merci pour votre message, les soignants Gynger vous répondront au plus vite !\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.",
      },
    },
    noLastMessage: 'Pas encore de messages',
    summary: {
      noSummary: "L'équipe de soignants n'a pas encore entré de notes pour cette conversation.",
      save: 'Sauver',
      caregiverPublicWarning: 'Attention! Ces notes sont visibles par les patientes',
      summaryPlaceholder:
        "Entrez ici la situation de la patiente et les recommandations faites par l'équipe de soignants",
      summarySaved: 'Notes mises à jour',
      summarySaveError: "Une erreur s'est produite lors de l'envoi des notes",
      summaryLoadError: "Une erreur s'est produite lors du chargement des notes",
    },
    list: {
      noChatsTitle: 'Pas de conversation pour le moment',
      noChats: {
        forPatient:
          'Pas encore de conversations.\n\nPosez vos questions à notre équipe de soignantes et soignants spécialisés en santé des femmes!',
        forCaregiver: "Pas de conversations pour le moment. Veuillez attendre qu'un patient pose une question",
      },
      errors: {
        fetch: "Une erreur s'est produite lors du chargement de vos conversations",
        sendMessage: "Une erreur s'est produite lors de l'envoi de votre message",
      },
    },
    new: {
      titleAskQuestion: 'Poser une question',
      titleHealthCheckUp: 'Faire le bilan',
      introMessageAskQuestion: `Bonjour, \n\n Posez-nous vos questions ici, l'équipe de soignants Gynger vous répondra au plus vite!\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.`,
      introMessageHealthCheckUp:
        "Bonjour,\n\nDécrivez-nous ici la raison de votre venue sur Gynger, et nous allons vous mettre en relation sous 24 heures avec votre sage-femme référente.\n\nElle vous aidera à dresser votre bilan santé, et nous ferons ensuite de notre mieux pour vous aider à aller mieux !\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.",
      introMessageSenderId: 'Gynger-User-Id',
      introMessageSenderName: 'Gynger',
      exampleMessages: [
        'Mes douleurs menstruelles augmentent depuis plusieurs mois, que faire ?',
        "Quels conseils nutrition avez-vous si je suis atteinte d'endométriose ?",
        'Comment augmenter mes chances de tomber enceinte ?',
        'Comment augmenter ma libido ?',
        'Comment trouver la bonne contraception ?',
        'Pourquoi ai-je des douleurs abdominales ?',
        'Quels vaccins dois-je réaliser pour mes enfants ?',
        'Comment savoir si j’approche de la ménopause ?',
      ],
    },
  },
  webSockets: {
    messages: {
      error: "Une erreur s'est produite, vous devrez peut-être recharger la page pour voir les derniers messages",
      connecting: 'Connexion à notre système de messagerie ...',
      connected: 'Vous recevrez les derniers messages',
    },
  },
  infoPage: {
    description: `Ces articles sont proposés par nos soignants pour mieux comprendre votre santé et améliorer votre bien-être.\nNous en publions régulièrement!`,
  },
  caregiversDirectory: {
    title: "Annuaire d'experts",
    infoContact: 'Info contact',
    description: `Trouvez des experts en santé des femmes proches de chez vous avec cet annuaire`,
    errors: {
      list: "Une erreur s'est produite lors du chargement de la liste des experts",
      noCaregiversFound: 'Aucun expert trouvé avec ces filtres',
    },
    filters: {
      title: 'Filtres',
      department: 'Département',
      caregiverGroup: 'Spécialisation',
    },
  },
  caregiverProfile: {
    title: 'Profil',
    errors: {
      fetch: "Une erreur s'est produite lors du chargement du profil de cet expert",
    },
  },
  onboarding: {
    carousel: {
      title: 'Bienvenue sur ',
      titleEnd: ' !',
      pages: {
        one: {
          text1start: 'Une équipe de soins ',
          text1Bold1: 'pluridisciplinaire',
          text1middle: ' et ',
          text1Bold2: 'spécialisée',
          text1end: ' en santé des femmes',
          text2start: 'Pour répondre à toutes vos questions ou vous accompagner à long terme via une messagerie ',
          text2Bold1: 'rapide',
          text2middle: ' et ',
          text2Bold2: 'sécurisée',
        },
        two: {
          textStart: 'Des ',
          textBold1: 'articles',
          textMiddle1: ' écrits par nos ',
          textBold2: 'professionnels',
          textMiddle2: ' pour ',
          textBold3: 'découvrir',
          textMiddle3: ' et mieux ',
          textBold4: 'comprendre',
        },
        three: {
          textStart: 'Un ',
          textBold1: 'annuaire',
          textMiddle1: ' de soignants spécialisés en santé des femmes ',
          textBold2: 'proches',
          textEnd: ' de chez vous',
        },
        four: {
          cards: {
            questions: 'Je pose une question',
            check: 'Je fais un bilan avec ma sage-femme',
            learn: 'Je veux en apprendre plus',
            expert: 'Je cherche un expert près de chez moi',
          },
        },
      },
      buttons: {
        next: "Ce n'est pas tout !",
        end: 'Je veux juste jeter un oeil',
      },
    },
  },
  faq: {
    subtitle: "Gynger est l'application de suivi de santé des femmes pour toutes.",
    networks: 'Retrouvez-nous sur les réseaux !',
    questions: 'Vous avez une question ?',
    videoUrl: 'https://www.youtube.com/embed/dTqWv8YUrH8?si=tJuZJin_y8v24Ji0',
  },
};

export default fr;
