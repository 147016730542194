module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Je pense être atteinte d’endométriose, quelle est la marche à suivre? 🤍💬
          </h1>

          <div class="date-modified color-secondary">Publié le 27 février 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
            Trois étapes clé dans le parcours des #EndogirlsGynger:
            </p>
            <p class="paragraph">
              Consulter un professionnel de santé 🧑‍⚕️🩺
              Si vous suspectez être atteinte d'endométriose, la première étape est de consulter un professionnel de santé, de préférence un gynécologue spécialisé dans les troubles de la santé féminine. Préparez-vous pour votre rendez-vous en notant vos symptômes, leur fréquence et leur gravité. N'hésitez pas à poser des questions et à exprimer toutes vos préoccupations.
            </p>
            <p class="paragraph">
              Si l’endométriose est suspectée, obtenez un diagnostic 📝
              Votre médecin peut effectuer un examen pelvien et vous recommander des tests supplémentaires tels qu'une échographie ou une IRM pour évaluer la présence de lésion en dehors de l'utérus. Dans certains cas, une laparoscopie, une procédure chirurgicale minimalement invasive, peut être nécessaire pour confirmer le diagnostic en examinant directement les tissus et les retirant.
            </p>
            <p class="paragraph">
              Une fois diagnostiquée, explorez les pistes de traitement 🤝
              Une fois le diagnostic posé, discutez avec votre médecin des options de traitement disponibles. Celles-ci peuvent inclure des médicaments pour soulager la douleur, des contraceptifs hormonaux pour réguler les menstruations, ou dans certains cas, une intervention chirurgicale pour enlever les lésions d'endométriose. Certains soins de support comme le yoga ou l’ostéopathie peuvent aussi être envisagés. Le soutien émotionnel et l’échange avec d'autres personnes atteintes d'endométriose peuvent également être bénéfiques!
            </p>
            <p class="paragraph">
            Rappelez-vous que vous n'êtes pas seule dans ce parcours, Gynger🎗️ vous accompagne au quotidien en complément des consultations physiques! Avec un diagnostic précoce et un plan de traitement approprié, il est possible de gérer les symptômes et de mener une vie épanouissante malgré l'endométriose.💪
            </p>
            <p class="paragraph">
              🤍💬 #EndoJourney #ParlerDeLaDouleur
            </p>
          </div>
        </div>
      </section>
    </div>
  </body>
</html>
`;
