import { Image } from 'antd';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';

export type LogoProps = {
  size?: 'default' | 'large';
};

const useStyle = createUseStyles({
  logo: {
    cursor: 'pointer',
  },
});

const Logo: React.FC<LogoProps> = ({ size = 'default' }) => {
  const navigate = useNavigate();
  const styles = useStyle();

  const goToHome = () => navigate(routes.home.route);

  const dimensions = () => {
    switch (size) {
      case 'large':
        return 100;

      case 'default':
      default:
        return 50;
    }
  };

  return (
    <Image
      alt="logo"
      height={dimensions()}
      width={dimensions()}
      preview={false}
      // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
      src={require('../../assets/logo.png')}
      onClick={goToHome}
      className={styles.logo}
    />
  );
};

export default Logo;
