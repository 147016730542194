import queryString from 'query-string';
import { t } from '../../locales/i18nManager';
import { ApiManager } from './ApiManager';
import { CaregiverDTO } from './models/CaregiverDTO';
import { CaregiverGroupDTO } from './models/CaregiverGroupDTO';

export type ApiCaregiversManagerGetCaregiverGroupsListResult = CaregiverGroupDTO[];
export type ApiCaregiversManagerGetAvailableCaregiverGroupsListResult = CaregiverGroupDTO[];
export type ApiCaregiversManagerGetCaregiversDirectoryParams = {
  zipCode?: string;
  caregiverGroupId?: string;
};
export type ApiCaregiversManagerGetCaregiversDirectoryResult = CaregiverDTO[];

export default class ApiCaregiversManager {
  static async getCaregiverGroupsList(): Promise<ApiCaregiversManagerGetCaregiverGroupsListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/caregiver-groups/`);

    return response.data.map((group: CaregiverGroupDTO) => {
      return {
        ...group,
        name: t(`caregiverGroups.${group.name}`),
      };
    });
  }

  static async getAvailableCaregiverGroupsList(): Promise<ApiCaregiversManagerGetAvailableCaregiverGroupsListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/caregiver-groups/available/`);

    return response.data.map((group: CaregiverGroupDTO) => {
      return {
        ...group,
        name: t(`caregiverGroups.${group.name}`),
      };
    });
  }

  static async getCaregiversDirectory(
    params?: ApiCaregiversManagerGetCaregiversDirectoryParams,
  ): Promise<ApiCaregiversManagerGetCaregiversDirectoryResult> {
    const apiManager = ApiManager.getInstance();

    const queryStringParamsObject = {
      ...(params?.zipCode && { address_zip_code__startswith: params.zipCode }),
      ...(params?.caregiverGroupId && { caregiver_groups: params.caregiverGroupId }),
    };

    const queryStringParams =
      Object.keys(queryStringParamsObject).length > 0 ? `?${queryString.stringify(queryStringParamsObject)}` : '';

    const response = await apiManager.get(`/api/caregivers/${queryStringParams}`);

    return response.data.map((caregiver: CaregiverDTO) => {
      return {
        ...caregiver,
        caregiver_groups: caregiver.caregiver_groups.map((group: CaregiverGroupDTO) => {
          return {
            ...group,
            name: t(`caregiverGroups.${group.name}`),
          };
        }),
      };
    });
  }
}
