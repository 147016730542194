import { t } from '../../locales/i18nManager';
import { ApiManager } from './ApiManager';
import { CaregiverGroupDTO } from './models/CaregiverGroupDTO';
import { RegisteredCaregiverDTO } from './models/RegisteredCaregiverDTO';

export type ApiRegisteredCaregiversManagerGetCaregiversListResult = RegisteredCaregiverDTO[];
export type ApiRegisteredCaregiversManagerGetCaregiverParams = {
  caregiverId: string;
};
export type ApiRegisteredCaregiversManagerGetCaregiverResult = RegisteredCaregiverDTO;

export default class ApiRegisteredCaregiversManager {
  static async getRegisteredCaregiversList(): Promise<ApiRegisteredCaregiversManagerGetCaregiversListResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/registered-caregivers/`);

    return response.data;
  }

  static async getRegisteredCaregiver(
    params: ApiRegisteredCaregiversManagerGetCaregiverParams,
  ): Promise<ApiRegisteredCaregiversManagerGetCaregiverResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/registered-caregivers/${params.caregiverId}/`);

    return {
      ...response.data,
      caregiver: {
        ...response.data.caregiver,
        caregiver_groups: response.data.caregiver.caregiver_groups.map((group: CaregiverGroupDTO) => {
          return {
            ...group,
            name: t(`caregiverGroups.${group.name}`),
          };
        }),
      },
    };
  }
}
