module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      h2 {
        font-size: 12pt;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            L’échographie pelvienne dans le diagnostic de l’endométriose
          </h1>

          <div class="date-modified color-secondary">Publié le 4 mai 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <h2>Pourquoi parle t-on d'échographie pelvienne dans la détection de l'endométriose ?</h2>
            <p class="paragraph">
              Pour découvrir si quelqu'un souffre d'endométriose, le professionnel de santé (que ce soit un médecin, un
              gynécologue ou une sage-femme) commence par poser des questions lors de la consultation. Ces professionnels vont
              demander à la personne plus de précisions sur ses symptômes, leur fréquence, leur intensité et leur durée pour aider
              à diriger le diagnostic. Ils effectueront également un examen clinique (gynécologique si possible), qui est l'un des
              deux principaux examens recommandés par la Haute Autorité de Santé (HAS), pour guider la prescription d'une
              échographie pelvienne ou d'une IRM pelvienne.
            </p>
            <p class="paragraph">
              La HAS recommande l’échographie pelvienne comme l’examen de première intention pour le diagnostic de l’endométriose,
              avec l’IRM en deuxième intention. Il est vraiment important de rechercher l'expertise d'un spécialiste de
              l'endométriose, car un praticien non formé à l’endométriose pourrait manquer le diagnostic. Il est donc recommandé
              de demander à votre médecin ou à votre gynécologue de vous diriger vers un spécialiste ou un centre spécialisé.
            </p>
            <h2>C’est quoi l’échographie pelvienne au juste ?</h2>
            <p class="paragraph">
              L’échographie pelvienne est un moyen pour les professionnels de la santé, de jeter un œil aux organes du pelvis (la
              partie basse de votre ventre). On parle ici de la vessie, du rectum et des organes reproducteurs internes (utérus et
              vagin). C'est une technique d'imagerie médicale qui utilise une sonde et des ultrasons pour montrer en temps réel
              des images sur un écran vidéo.
            </p>
            <ul>
              <li>Faut-il se préparer pour l’examen ?</li>
            </ul>
            <p class="paragraph">
              Pas de soucis, l’échographie pelvienne ne nécessite pas de préparation particulière. Sauf si on vous dit le
              contraire lors de la prescription, on peut parfois vous demander (selon le type d’échographies pelviennes) de ne pas
              manger quelques heures avant l'échographie, ou d'avoir la vessie vide ou pleine.
            </p>
            <ul>
              <li>Comment va se dérouler l’examen ?</li>
            </ul>
            <p class="paragraph">Il existe 2 types d’échographie pelviennes :</p>
            <p class="paragraph">
              Premièrement, il y a l'échographie transcutanée. Dans cette méthode, une sonde est simplement placée sur la peau de
              votre bas-ventre. Vous vous allongez sur le dos sur la table d'examen, et votre médecin applique un peu de gel frais
              sur votre peau pour faciliter le contact entre la sonde et votre peau. Ensuite, avec une légère pression, la sonde
              est déplacée sur votre peau pour obtenir des images claires de vos organes internes.
            </p>
            <p class="paragraph">
              Deuxièmement, il y a l'échographie endocavitaire, également connue sous le nom d'échographie endovaginale. Pour
              celle-ci, une sonde est introduite délicatement dans le vagin. Ne vous inquiétez pas, elle est toujours protégée par
              une gaine stérile en latex et recouverte d'un lubrifiant pour votre confort. Vous vous allongez de la même manière
              sur la table d'examen, et la sonde est insérée pour obtenir des images encore plus détaillées, y compris du col de
              l'utérus.
            </p>
            <p class="paragraph">
              En ce qui concerne la durée, cela varie selon les professionnels, mais généralement, une échographie pelvienne dure
              entre 10 et 25 minutes. C'est un court laps de temps pour obtenir des informations importantes sur votre santé
              reproductive.
            </p>
            <h2>Quelques petites recommandations</h2>
            <ol>
              <li>
                Choisissez des vêtements confortables : Optez pour des vêtements qui sont faciles à retirer, surtout si vous savez
                que vous passerez par un examen comme une échographie. Cela vous permettra de vous sentir plus à l'aise pendant la
                procédure.
              </li>
              <li>
                Communiquez avec votre professionnel de santé : Ne sous-estimez pas le pouvoir de la communication ! N'hésitez pas
                à discuter avec votre médecin avant et pendant l'examen. Posez des questions, exprimez vos préoccupations et
                assurez-vous de comprendre ce qui se passe à chaque étape.
              </li>
              <li>
                Détendez-vous et respirez : Pendant l'échographie, essayez de vous détendre autant que possible. Respirez
                profondément et régulièrement pour vous aider à rester calme. Cela peut contribuer à atténuer tout inconfort
                éventuel que vous pourriez ressentir.
              </li>
              <li>
                Exprimez vos sensations : Si vous ressentez de la douleur ou de l'inconfort pendant l'examen, n'hésitez pas à le
                signaler à votre professionnel de santé. Ils sont là pour s'assurer que vous vous sentez à l'aise et en sécurité,
                donc ne souffrez pas en silence !
              </li>
            </ol>
            <p class="paragraph">
              En suivant ces conseils simples, vous pouvez rendre votre expérience d'examen médical plus confortable et plus
              productive. N'oubliez pas que votre bien-être est la priorité numéro un, alors n'hésitez jamais à parler et à
              exprimer vos besoins pendant l'examen.
            </p>
            <h2>****</h2>
            <h2>Qu’en est-il de l’interprétation des résultats ?</h2>
            <p class="paragraph">
              Après l'échographie, votre professionnel de santé vous fournira un compte rendu détaillé de ses observations et
              interprétations. Ce compte rendu sera également envoyé à votre médecin référent pour qu'il soit au courant des
              résultats.
            </p>
            <p class="paragraph">
              Dans certains cas, des examens supplémentaires comme une IRM peuvent être recommandés pour confirmer le diagnostic.
              En effet, certaines lésions ne sont pas toujours détectables uniquement par échographie.
            </p>
            <p class="paragraph">
              Une fois le diagnostic établi, votre médecin traitant pourra demander d'autres examens complémentaires si nécessaire
              pour assurer une prise en charge appropriée de votre santé.
            </p>
          </div>
        </div>
      </section>
    </div>
  </body>
</html>
`;
