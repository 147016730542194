module.exports = `
<html class="style-black-1 custom_fonts comps live_website" lang="" data-mci-setup="true">
  <head>
    <style>
      .custom_fonts .custom-google-fonts-enabled * {
        font-family: 'Archivo', Helvetica, sans-serif;
      }
      .color-secondary {
        color: #aaa;
      }
      .custom_fonts .custom-google-fonts-enabled h1,
      .custom_fonts .custom-google-fonts-enabled h2,
      .custom_fonts .custom-google-fonts-enabled h3,
      .custom_fonts .custom-google-fonts-enabled h4,
      .custom_fonts .custom-google-fonts-enabled h5,
      .custom_fonts .custom-google-fonts-enabled h6 {
        font-family: 'Archivo Narrow', Helvetica, serif;
      }
      
      .ms-editor-squiggler {
        display: none !important;
        visibility: hidden !important;
      }
      editor-card {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
        visibility: hidden !important;
      }
      .AsanaMain .TaskRow-taskName,
      .AsanaMain .TaskName-input {
        min-height: 21px !important;
      }
      .SpellingErrorV2 {
        background-image: none !important;
        border-bottom: none !important;
        pointer-events: none !important;
      }
      .sketchy-text-spelling-error-underline-overlay {
        display: none;
      }
      .reposition__StyledWrapperDiv-sc-mjfw5a-0 {
        z-index: 9999999;
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        src:
          local('Inter-Regular'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Regular.woff2) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        src:
          local('Inter-Medium'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Medium.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        src:
          local('Inter-SemiBold'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-SemiBold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        src:
          local('Inter-Bold'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-Bold.woff) format('woff');
      }
      @font-face {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        src:
          local('Inter-ExtraBold'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Inter-ExtraBold.woff) format('woff');
      }
      @font-face {
        font-family: Tropiline;
        font-style: normal;
        font-weight: 700;
        src:
          local('Tropiline-Bold'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff2) format('woff2'),
          url(https://assets.merci-app.com/fonts/Tropiline-Bold.woff) format('woff');
      }
      .kix-spellcheck-with-explanation-bubble,
      .kix-spell-bubble {
        display: none !important;
      }
      #ReadingPaneContainerId .customScrollBar {
        height: 100% !important;
        transform: scale(1) !important;
        width: 100% !important;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] {
        display: none;
      }
      .ms-font-s i[data-icon-name='ZoomIn'] + i[data-icon-name='ChevronDownRegular'] {
        display: none;
      }
    </style>
  </head>
  <body
    class="custom-google-fonts-enabled comps body--desktop body--loaded mci-c4f7c3b940b2fda375c61f1064b5e7f6"
    id="109837-12397-46273"
    data-new-gr-c-s-check-loaded="14.1157.0"
    data-gr-ext-installed=""
    spellcheck="false"
  >
    <div class="blog_post-01 bg-main">
      <section class="overlay-post read-more-zoom" id="js-overlay-list" style="transform: scale(1)">
        <div class="container container-p--large">
          <h1 class="post-title">
            Quelles sont les thérapies complémentaires qui peuvent m'aider à soulager mon endométriose? 🌿💆‍♀️
          </h1>

          <div class="date-modified color-secondary">Publié le 6 mars 2024 - Auteur: <a target="_blank" href="https://www.linkedin.com/in/quentin-marquet-pharmacist-hec-gynger-qvt-rse-marqueemployeur/">Quentin MARQUET</a> (Pharmacien)</div>

          <div class="post-body content_box content_box--blog content_box--blog-editorjs">
            <p class="paragraph">
            En plus des traitements médicaux conventionnels, de nombreuses thérapies complémentaires peuvent offrir un soulagement aux personnes souffrant d'endométriose. Il est important de noter que ces approches peuvent varier en efficacité d'une personne à l'autre, et il est toujours recommandé de consulter votre professionnel de santé avant d'entreprendre toute nouvelle thérapie.
            </p>
            <p class="paragraph">
            💆 La chiropraxie et l'ostéopathie sont deux approches qui peuvent aider à soulager les douleurs pelviennes en réalignant le corps et en relâchant les tensions musculaires. De même, la kinésithérapie peut fournir des exercices spécifiques pour renforcer les muscles pelviens et améliorer la posture, réduisant ainsi l'inconfort associé à l'endométriose.
             </p>
            <p class="paragraph">
            🧘 Certaines personnes trouvent un soulagement grâce à des pratiques de relaxation telles que le yoga, la méditation et la respiration profonde. Ces techniques peuvent aider à réduire le stress et à gérer la douleur de manière plus efficace. De plus, des approches comme l'acupuncture pour détendre les muscles, ce qui peut atténuer les symptômes de l'endométriose.
             </p>
            <p class="paragraph">
            🏃‍♀️ Enfin, l'alimentation et les changements de mode de vie peuvent également jouer un rôle important dans la gestion de l'endométriose. Certains régimes alimentaires, tels que ceux riches en fruits, légumes et aliments anti-inflammatoires, peuvent aider à réduire l'inflammation et les symptômes douloureux.
             </p>
            <p class="paragraph">
            N'oubliez pas que chaque personne est unique, et il est important d'explorer différentes options pour trouver ce qui fonctionne le mieux pour vous. Sur Gynger 🎗️, vous pouvez échanger avec nos thérapeutes pour élaborer un plan de traitement global qui répond à vos besoins et vous aide à vivre pleinement malgré l'endométriose.
             </p>
            <p class="paragraph">
            🌿💆‍♀️ #ThérapiesComplémentaires #BienÊtreEndo
            </p>
          </div>
        </div>
      </section>
    </div>
  </body>
</html>
`;
