import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import CaregiverProfileModal from 'components/modals/CaregiverProfileModal';
import AppColors from 'config/AppColors';
import { useEffect, useRef, useState } from 'react';
import { MessageList, MessageType } from 'react-chat-elements';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ConversationMessage } from 'services/api/models/ConversationDTO';
import { UserDTO } from 'services/api/models/UserDTO';
import { selectCurrentConversationSummary } from 'store/conversations/selectors';
import { MessageSummary } from 'store/conversations/types';
import { selectCurrentUser } from 'store/users/selectors';
import MessageUtil, { ChatMessage } from '../../models/Message';
import './MessagesList.css';

export type MessagesListProps = {
  messages: ConversationMessage[];
};

const useStyles = createUseStyles({
  container: {
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: AppColors.backgroundGrey,
  },
  messagesList: {
    paddingTop: 20,
    paddingBottom: 20,

    '& .rce-mbox-right': {
      backgroundColor: 'rgb(217,246,218)',
      background: 'linear-gradient(130deg, rgba(219, 255, 239,1) 0%, rgba(191,233,216,1) 100%)',
    },

    '& .rce-mbox': {
      maxWidth: '80%',
      whiteSpace: 'pre-line',
      borderRadius: '13px !important',
      boxShadow: '2px 2px 4px 1px #ccc3',
      padding: '10px 12px 8px',
    },

    '& .rce-mbox-text': {
      fontSize: '18px !important',
    },
  },
});

export default function MessagesList(props: MessagesListProps) {
  const { messages } = props;

  // Hooks
  const ref = useRef();
  const styles = useStyles();
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  // Selectors
  const currentUser = useSelector(selectCurrentUser);
  const currentConversationSummary = useSelector(selectCurrentConversationSummary);

  // State
  const [caregiverIdDisplayedInModal, setCaregiverIdDisplayedInModal] = useState(undefined as string | undefined);

  if (Capacitor.isPluginAvailable('Keyboard')) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
    });
  }

  useEffect(() => {
    // Get all message boxes
    const messageBoxHtmlElements = document.getElementsByClassName('rce-container-mbox');
    if (messageBoxHtmlElements) {
      const nbOfMessageBoxHtmlElements = messageBoxHtmlElements.length;
      if (nbOfMessageBoxHtmlElements > 0) {
        // Scroll to last item
        messageBoxHtmlElements[nbOfMessageBoxHtmlElements - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [messages, keyboardVisible]);

  function closeCaregiverProfileModal() {
    setCaregiverIdDisplayedInModal(undefined);
  }

  return (
    <div className={styles.container}>
      {caregiverIdDisplayedInModal && (
        <CaregiverProfileModal
          caregiverId={caregiverIdDisplayedInModal}
          open
          onClose={() => closeCaregiverProfileModal()}
        />
      )}
      <MessageList
        referance={ref}
        className={styles.messagesList}
        // Cast to MessageType[] because we override the default data type
        dataSource={
          MessageUtil.convertMessagesToComponentDataType(
            messages,
            currentUser as UserDTO,
            currentConversationSummary as MessageSummary,
          ) as MessageType[]
        }
        lockable={false}
        toBottomHeight="100%"
        messageBoxStyles={{ color: 'rgba(46, 46, 46, 0.88)', lineHeight: '22px' }}
        onTitleClick={messageDetails => {
          // Only show the caregiver profile when current user is a patient and when the message has been sent by a caregiver
          if (
            (messageDetails as ChatMessage).sender?.is_caregiver &&
            (messageDetails as ChatMessage).sender?.id &&
            !currentUser?.is_caregiver
          ) {
            setCaregiverIdDisplayedInModal((messageDetails as ChatMessage).sender?.id);
          }
        }}
      />
    </div>
  );
}
