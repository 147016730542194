import { round } from 'lodash';

export default class PercentUtils {
  static getTimePercentage(value: number, nbOfIncrement: number) {
    // Get the number of parts from a `value` with a step of `nbOfIncrement`
    const intervalTime = value / nbOfIncrement;

    // Convert the number to a percentage
    const incrementPercentage = round(100 / nbOfIncrement) + 1;

    return { intervalTime, incrementPercentage };
  }
}
